<template>

  <div class="row">
    <div class="col-sm-9">
      <error v-if="templateDeleteError">
        {{ templateDeleteErrorMessage }}
      </error>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-9">

      <div class="template-accordion accordion mb-3"
           :id="'templateAccordion_' + template.id"
      >
        <div class="card">
          <div class="card-header custom-accordion"
               :id="'templateHeading_' + template.id"
          >
            <h2 class="mb-0">
              <button class="btn btn-link btn-block text-left collapsed"
                      type="button"
                      data-toggle="collapse"
                      :data-target="'#templateCollapse_' + template.id"
                      aria-expanded="false"
                      :aria-controls="'templateCollapse_' +  template.id"
              >
                {{ template.title }}
              </button>
            </h2>
          </div>
          <div :id="'templateCollapse_' + template.id"
               class="collapse"
               :aria-labelledby="'templateHeading_' + template.id"
               :data-parent="'#templateAccordion_' + template.id"
          >
            <div class="card-body">

              <template-mapping :template="template"></template-mapping>

            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="col-sm-3">

      <action-button type="danger"
                     text="Delete"
                     :spin="spinners['delete-' + template.id]"
                     :disabled="spinners['delete-' + template.id]"
                     v-on:click="deleteTemplate(template)"
      ></action-button>

    </div>
  </div>

</template>

<script>
  import TemplateMapping from "./TemplateMapping";
  import api from "../../services/api";
	const TEMPLATES_ENDPOINT = 'templates';

  export default {
    components: {
      TemplateMapping
    },
    props: {
      template: {}
    },
    data: () => ({
      spinners: {},
      templateDeleteError: false,
      templateDeleteErrorMessage: ''
    }),
    methods: {
      deleteTemplate(template) {
        this.spinners['delete-' + template.id] = true
        this.templateDeleteError = false
        this.templateDeleteErrorMessage = ''

        const self = this
        api({
            endpoint: `${TEMPLATES_ENDPOINT}/${this.template.id}`,
            method: 'DELETE',
            addTokenToHeaders: true
        })
          .then(function (res) {
            if (!res.data || res.data.status !== 'success') {
              this.templateDeleteError = true
              this.templateDeleteErrorMessage = res.data.message
            } else {
              this.$emit('delete-template')
              this.spinners['delete-' + template.id] = false
            }
          }.bind(this),
          function(error) {
            self.spinners['delete-' + template.id] = false
            self.templateDeleteError = true
            self.templateDeleteErrorMessage = 'Error: ' + error
          })
      }
    }
  }
</script>