<template>
  <tr>
    <td>{{ salesRep.id }}</td>
    <td>
      <input type="text"
             class="form-control"
             v-model="data.firstname"
             v-bind:class="{'is-invalid': hasErrors['firstname']}"
      />
      <small class="text-danger">
        {{ hasErrors['firstname'] }}
      </small>
    </td>
    <td>
      <input type="text"
             class="form-control"
             v-model="data.lastname"
             v-bind:class="{'is-invalid': hasErrors['lastname']}"
      />
      <small class="text-danger">
        {{ hasErrors['lastname'] }}
      </small>
    </td>
    <td class="text-center">
      <action-button icon="fa-save"
                     text="Update"
                     :spin="spinners['update-' + salesRep.id]"
                     :disabled="spinners['update-' + salesRep.id]"
                     v-on:click="updateSalesRep(salesRep)"
      ></action-button>
    </td>
    <td class="text-center">
      <error v-if="deleteSalesRepError">
        {{ deleteSalesRepError }}
      </error>

      <action-button type="danger"
                     icon="fa-trash"
                     text="Delete"
                     :spin="spinners['delete-' + salesRep.id]"
                     :disabled="spinners['delete-' + salesRep.id]"
                     v-on:click="deleteSalesRep(salesRep)"
      ></action-button>
    </td>
  </tr>
</template>

<script>
  import api from "../../services/api";

  const SALES_REPS_ENDPOINT = 'sales_reps'

  export default {
    props: {
      salesRep: {
        type: Object,
        required: true
      }
    },
    data: () => ({
      hasErrors: {},
      spinners: {},
      data: {},
      deleteSalesRepError: false,
      deleteSalesRepErrorMessage: ''
    }),
    created: function() {
      this.data = {
        firstname: this.salesRep.firstname,
        lastname: this.salesRep.lastname
      }
    },
    methods: {
      updateSalesRep(salesRep) {
        this.spinners['update-' + salesRep.id] = true
        this.hasErrors = {}

        api({
            endpoint: `${SALES_REPS_ENDPOINT}/${this.salesRep.id}`,
            method: 'PUT',
            data: this.data,
            addTokenToHeaders: true
        })
          .then(function (res) {
            if (res.data.errors && Object.keys(res.data.errors).length > 0) {
              Object.keys(res.data.errors).forEach(fieldName => {
                this.hasErrors[fieldName] = res.data.errors[fieldName]
              })
            }
            this.spinners['update-' + salesRep.id] = false
          }.bind(this))
      },
      deleteSalesRep(salesRep) {
        this.spinners['delete-' + salesRep.id] = true
        this.deleteSalesRepError = false
        this.deleteSalesRepErrorMessage = ''

        const self = this
        api({
            endpoint: `${SALES_REPS_ENDPOINT}/${this.salesRep.id}`,
            method: 'DELETE',
            addTokenToHeaders: true
        })
          .then(function (res) {
            if (!res.data || res.data.status !== 'success') {
                this.deleteSalesRepError = true
                this.deleteSalesRepErrorMessage = 'Error deleting sales rep'
            } else {
              this.$emit('delete-sales-rep')
              this.spinners['delete-' + salesRep.id] = false
            }
          }.bind(this),
          function(error) {
            self.spinners['delete-' + salesRep.id] = false
            self.deleteSalesRepError = true
            self.deleteSalesRepErrorMessage = 'Error: ' + error
          })
      }
    },
  }
</script>