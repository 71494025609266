import { createWebHistory, createRouter } from "vue-router";

import routes from './web'
import { getExpirationDateFromToken } from '../services/helpers'

const zohobooksTokenName = process.env.VUE_APP_ZOHOBOOKS_TOKEN_NAME

const baseHref = process.env.NODE_ENV === 'development' ? '/' : '/zohobooks/'
const router = createRouter({
  history: createWebHistory(baseHref),
  routes
});

router.beforeEach((to, from, next) => {
  let userIsLoggedIn = false
  const JWTToken = router.app.$cookies.get(zohobooksTokenName)
  const exp = getExpirationDateFromToken(JWTToken)

  if (exp) {
    const expirationTime = parseInt(exp)
    const currentTimeStamp = Math.floor(Date.now() / 1000);

    // Add 2 seconds just to be sure
    userIsLoggedIn = currentTimeStamp + 2 <= expirationTime;
  }
  if (!userIsLoggedIn) {
    router.app.$cookies.remove(zohobooksTokenName)
    if (to.name !== 'login') next({ name: 'login' })
    else next()
  } else {
    if (to.name === 'login') next({ name: 'dashboard' })
    else next()
  }
})

export default router;