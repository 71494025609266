<template>
  <li class="nav-item">
    <router-link class="nav-link"
                 :to="href"
                 v-bind:class="{'active': isActive}"
     >
      <span v-html="iconSvg"></span>
      <slot></slot>
    </router-link>
  </li>
</template>

<script>
import feather from 'feather-icons'
export default {
  props: {
    href: {
      type:String,
      required: true
    },
    icon: {
      type:String,
      required: true
    },
    isActive: {
      type:Boolean
    }
  },
  computed: {
    iconSvg: function () {
      return feather.toSvg(this.icon)
    }
  }
}
</script>