<template>
  <tr class="sales-row"
      v-bind:class="{
        green: sale.price_amount/sale.cost_amount >= 1.25,
        yellow: sale.price_amount/sale.cost_amount > 1.1 && sale.price_amount/sale.cost_amount < 1.25,
        red: sale.price_amount/sale.cost_amount <= 1.1,
      }"
  >
    <td>
      <div class="form-check">
        <input type="checkbox"
               class="form-check-input"
               v-model="idForBulkActionValue"
               @change="addSaleToBulkActionIds(sale.id)"
        />
      </div>
    </td>
    <td>{{ sale.date }}</td>
    <td><span v-if="sale.device">{{ sale.device.serial_number }}</span></td>
    <td>{{ sale.cost_amount }}</td>
    <td>{{ sale.price_amount }}</td>
    <td>{{ getProfit() }}</td>
    <td>{{ formatValue((sale.price_amount/sale.cost_amount - 1)*100) }}</td>
    <td>{{ getSalesRepBySlug(sale.device.sales_rep) }}</td>
  </tr>
</template>

<script>
  import {formatValue, toLowerCase} from "../../services/helpers";
  export default {
    props: {
      sale: { type: Object },
      salesReps: { type: Array }
    },
    data: () => ({
      idForBulkActionValue: false
    }),
    methods: {
      formatValue: formatValue,
      toLowerCase: toLowerCase,
      addSaleToBulkActionIds(saleId) {
        const keyValue = {}
        keyValue[saleId] = this.idForBulkActionValue
        this.$emit('idsForBulkActionUpdate', keyValue)
      },
      getProfit() {
        if (this.sale) {
          const roundedPriceAmount = this.sale.price_amount.toFixed(2)
          const roundedCostAmount = this.sale.cost_amount.toFixed(2)
          return Math.floor( (roundedPriceAmount - roundedCostAmount) * 100) / 100
        }
        return null
      },
      getSalesRepBySlug(slug) {
        let keepGoing = true
        let salesRepName = null
        this.salesReps.forEach(rep => {
          if( keepGoing ) {
            if( slug === this.toLowerCase(rep.firstname) ) {
              salesRepName = rep.firstname + " " + rep.lastname;
              keepGoing = false;
            }
          }
        });

        return salesRepName;
      }
    }
  }
</script>