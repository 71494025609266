require('dotenv').config()

import { createApp } from 'vue'

import ActionButton from "./components/ActionButton";
import DashboardSpinner from "./components/DashboardSpinner";
import Error from "./components/Error";
import H2Title from "./components/H2Title";
import Main from './layouts/Main.vue'
import Pagination from 'v-pagination-3';
import router from './routes/router'
import VueCookies from 'vue3-cookies'

import 'jquery/src/jquery.js'
import 'bootstrap/dist/js/bootstrap.min.js'
import './assets/bootstrap-4.6.0.min.css';
import './assets/dashboard.css';
import './assets/font-awesome-4.7.0.min.css';

const app = createApp(Main)
            .use(VueCookies)
            .use(router)
            .component('ActionButton', ActionButton)
            .component('DashboardSpinner', DashboardSpinner)
            .component('Error', Error)
            .component('H2Title', H2Title)
            .component('Pagination', Pagination)
            .mount('#app')

// To access the cookies in Navigation guards
router.app = app
