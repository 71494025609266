<template>
    <header class="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
    <a class="navbar-brand col-md-3 col-lg-2 me-0 px-3"
       href="https://perfectcopier.com"
       target="_blank"
    >
      Pahoda Image Products
    </a>
    <ul class="navbar-nav px-3">
      <li class="nav-item text-nowrap">
        <a class="nav-link"
           href="#"
           @click.prevent="logOut()"
        >
          Sign out
        </a>
      </li>
    </ul>
  </header>

  <div class="container-fluid">
    <div class="row">

      <sidebar></sidebar>

      <dashboard></dashboard>

    </div>
  </div>
</template>


<script>
import Dashboard from '../components/Dashboard.vue'
import Sidebar from '../components/Siderbar.vue'

const zohobooksTokenName = process.env.VUE_APP_ZOHOBOOKS_TOKEN_NAME

export default {
  components: {
    Dashboard,
    Sidebar
  },
  methods: {
    logOut() {
      this.$cookies.remove(zohobooksTokenName)
      this.$router.replace({name:'login'})
    }
  }
}
</script>