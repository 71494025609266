<template>

  <h2-title :title="'Sales Reps'"></h2-title>

  <div class="alert alert-warning" v-if="error">
    {{ error }}
  </div>

  <div class="row">
    <div class="col-sm-3">
      <div class="form-group">
        <label for="repFirstname">Firstname:</label>
        <input id="repFirstname" type="text" class="form-control" v-model="repFirstname" />
      </div>
    </div>
    <div class="col-sm-3">
      <div class="form-group">
        <label for="repLastname">Lastname:</label>
        <input id="repLastname" type="text" class="form-control" v-model="repLastname" />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-3">
      <div class="form-group">
        <action-button type="secondary"
                       icon="fa-plus"
                       text="Add Sales Rep"
                       :spin="spinners['add-sales-rep']"
                       :disabled="repFirstname.length === 0 || repLastname.length === 0 || spinners['add-sales-rep']"
                       v-on:click="addSalesRep()"
        ></action-button>
      </div>
    </div>
  </div>

  <dashboard-spinner v-if="salesReps.length === 0"></dashboard-spinner>

  <div class="table-responsive" v-if="salesReps.length > 0">
    <table class="table table-striped table-sm">
      <thead>
        <tr>
          <th>Id</th>
          <th>First name</th>
          <th>Last name</th>
          <th>&nbsp;</th>
          <th>&nbsp;</th>
        </tr>
      </thead>

      <tbody>
        <sales-rep-row v-for="(salesRep, index) in salesReps"
                       :key="salesRep.id"
                       :salesRep='salesRep'
                       v-on:delete-sales-rep="deleteSalesRep(index)"
        ></sales-rep-row>
      </tbody>

    </table>
  </div>

  <h2-title :title="'Sales'"></h2-title>

  <div class="row">
    <div class="col-sm-12">
      <b>Profits:</b>
      <br>
    </div>
    <div class="col-sm-12">
      <div class="row m-0">
        <div class="col-sm-4 sales-profits-cell"
             v-for="salesRep in salesReps"
             :key="salesRep.firstname + '_' + salesRep.lastname"
        >

          <sale-profit :salesRep="salesRep"
                       :sales="sales"
                       :currentMonthTotalPrice="currentMonthTotalPrices[toLowerCase(salesRep.firstname)]"
                       :currentMonthTotalCost="currentMonthTotalCosts[toLowerCase(salesRep.firstname)]"
                       :previousMonthTotalPrice="previousMonthTotalPrices[toLowerCase(salesRep.firstname)]"
                       :previousMonthTotalCost="previousMonthTotalCosts[toLowerCase(salesRep.firstname)]"
                       :totalPrice="totalPrices[toLowerCase(salesRep.firstname)]"
                       :totalCost="totalCosts[toLowerCase(salesRep.firstname)]"
          ></sale-profit>

        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12 mt-3">
      <h6><b>Filters:</b></h6>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-sm-3">
      Sales Reps:
      <select class="form-control"
              v-model="filterSalesBySalesRep"
              @change="filterSales()"
        >
        <option value="">
          All
        </option>
        <option v-for="salesRep in salesReps"
                v-bind:value="toLowerCase(salesRep.firstname)"
                :key="salesRep.id"
        >
          {{ salesRep.firstname }} {{ salesRep.lastname }}
        </option>
      </select>
    </div>

    <div class="col-sm-3">
      Profits:
      <select class="form-control"
              v-model="filterSalesByProfitAmount"
              @change="filterSales()"
      >
        <option value="">
          All
        </option>
        <option value="25">
          More than 25%
        </option>
        <option value="10">
          From 10% to 25%
        </option>
        <option value="5">
          Less than 10%
        </option>
      </select>
    </div>

    <div class="col-sm-3">
      Months:
      <select class="form-control"
              v-model="filterSalesByMonth"
              @change="filterSales()"
        >
        <option value="">
          All
        </option>
        <option v-for="(key, value) in salesYearsMonths"
                :value="value"
                :key="value"
        >
          {{ key }}
        </option>
      </select>
    </div>

    <div class="col-sm-3">
      Show resuls:
      <select class="form-control"
              v-model="salesDisplay"
              @change="filterSales()"
      >
        <option value="paged">
          Paged
        </option>
        <option value="all">
          All
        </option>
      </select>
    </div>
  </div>

  <dashboard-spinner v-if="sales.length === 0"></dashboard-spinner>

  <div class="table-responsive" v-if="sales.length > 0">

    <pagination v-model="currentPage"
                :records="filteredSales.length"
                :per-page="recordsPerPage"
                v-if="salesDisplay === 'paged'"
    />

    <table class="table table-striped table-sm">
      <thead>
        <tr>
          <th>&nbsp;</th>
          <th>Date</th>
          <th>Device serial number</th>
          <th>Cost amount</th>
          <th>Price amount</th>
          <th>Profit($)</th>
          <th>Profit(%)</th>
          <th>Sales rep</th>
        </tr>
      </thead>

      <tbody v-if="salesDisplay === 'paged'">
        <sale-row v-for="sale in paginatedSales[currentPage - 1]"
                  :key="sale.id"
                  :sale='sale'
                  :salesReps='salesReps'
                  @idsForBulkActionUpdate="idsForBulkActionUpdate"
        ></sale-row>
      </tbody>
      <tbody v-if="salesDisplay === 'all'">
        <sale-row v-for="sale in filteredSales"
                  :key="sale.id"
                  :sale='sale'
                  :salesReps='salesReps'
                  @idsForBulkActionUpdate="idsForBulkActionUpdate"
        ></sale-row>

        <tr>
          <th></th>
          <th></th>
          <th><b>Totals:</b></th>
          <th>{{ formatValue(totalCostAmount) }}</th>
          <th>{{ formatValue(totalPriceAmount) }}</th>
          <th>{{ formatValue(totalPriceAmount - totalCostAmount) }}</th>
          <th></th>
          <th></th>
        </tr>
      </tbody>

    </table>
  </div>

  <div class="row mt-3 mb-3">
    <div class="col-sm-4">
      <error v-if="salesBulkActionError">
        {{ salesBulkActionErrorMessage }}
      </error>
    </div>
  </div>
  <div class="row mt-3 mb-3">
    <div class="col-sm-2">
      <select class="form-control"
              v-model="bulkAction"
      >
        <option value="" selected>
          Bulk Actions
        </option>
        <option value="delete">
          Delete
        </option>
      </select>
    </div>
    <div class="col-sm-9">

      <action-button type="danger"
                     icon="fa-hand-o-left"
                     text="Apply"
                     :spin="spinners['bulk-actions']"
                     :disabled="bulkAction === '' || idsForBulkAction.length === 0 || spinners['bulk-actions']"
                     v-on:click="applyBulkAction()"
      ></action-button>

    </div>
  </div>


</template>

<script>
import SalesRepRow from "./rows/SalesRepRow";
import SaleProfit from "../components/SaleProfit";
import SaleRow from "./rows/SaleRow";

import api from "../services/api";
import {formatValue, paginateRecords, toLowerCase} from "../services/helpers";

const SALES_ENDPOINT = 'sales/'
const SALES_REPS_ENDPOINT = 'sales_reps/'

export default {
  components: {
    SaleProfit,
    SaleRow,
    SalesRepRow
  },
  data: () => ({
    idsForBulkAction: [],
    currentPage: 1,
    recordsPerPage: 25,
    sales: [],
    salesReps: [],
    error: '',
    filterSalesBySalesRep: '',
    filterSalesByProfitAmount: '',
    filterSalesByMonth: '',
    salesDisplay: 'paged',
    salesYearsMonths: {},
    filteredSales: [],
    paginatedSales: [],
    repFirstname: '',
    repLastname: '',
    spinners: {},
    totalCosts: {},
    totalPrices: {},
    previousMonthTotalCosts: {},
    previousMonthTotalPrices: {},
    currentMonthTotalCosts: {},
    currentMonthTotalPrices: {},
    bulkAction: '',

    totalCostAmount: 0,
    totalPriceAmount: 0,
    totalProfitAmount: 0,

    currentMonthTotalPrice: {},
    currentMonthTotalCost: {},
    previousMonthTotalPrice: {},
    previousMonthTotalCost: {},
    totalPrice: {},
    totalCost: {},
    salesBulkActionError: false,
    salesBulkActionErrorMessage: ''
  }),
  created: function() {
    api({ endpoint: `${SALES_ENDPOINT}` })
      .then(function (res) {
          this.sales = res.data
          this.filteredSales = this.sales;
          this.paginatedSales = paginateRecords(this.filteredSales, this.recordsPerPage)

          const monthsOfTheYear = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
          ]
          const currentDate = new Date();
          const currentYear = currentDate.getYear();
          const currentMonth = currentDate.getMonth();
          const previousMonth = currentMonth > 0 ? ( currentDate.getMonth() - 1 ) : 11;

          this.sales.forEach(sale => {
            const dateParams = sale.date.split('-');
            const saleDate = new Date(dateParams[0], parseInt(dateParams[1]) - 1, dateParams[2]);
            const saleYear = saleDate.getYear();
            const saleMonth = saleDate.getMonth();

            const parsedSaleMonth = monthsOfTheYear[saleMonth];
            let parsedSaleYear = saleYear + 1900;
            parsedSaleYear = parsedSaleYear.toString();

            const saleYearMonthString = parsedSaleMonth + " " + parsedSaleYear;

            if( Object.values(this.salesYearsMonths).indexOf( saleYearMonthString < 0 ) ) {
              this.salesYearsMonths[saleMonth + '-' + parsedSaleYear] = saleYearMonthString;
            }

            if (sale.device && sale.device.sales_rep) {
              const salesRep = sale.device.sales_rep
              if (!this.totalCosts[salesRep]) this.totalCosts[salesRep] = 0;
              if (!this.totalPrices[salesRep]) this.totalPrices[salesRep] = 0;
              if (!this.currentMonthTotalCosts[salesRep]) this.currentMonthTotalCosts[salesRep] = 0;
              if (!this.currentMonthTotalPrices[salesRep]) this.currentMonthTotalPrices[salesRep] = 0;
              if (!this.previousMonthTotalCosts[salesRep]) this.previousMonthTotalCosts[salesRep] = 0;
              if (!this.previousMonthTotalPrices[salesRep]) this.previousMonthTotalPrices[salesRep] = 0;

              this.totalCosts[salesRep] += parseFloat(sale.cost_amount);
              this.totalPrices[salesRep] += parseFloat(sale.price_amount);

              if(
                currentYear == saleYear
                  ||
                ( previousMonth == 11
                  &&
                 (currentYear - saleYear) == 1)
              ) {
                switch( saleMonth ) {
                  case currentMonth :
                    this.currentMonthTotalCosts[salesRep] += parseFloat(sale.cost_amount);
                    this.currentMonthTotalPrices[salesRep] += parseFloat(sale.price_amount);

                    break;
                  case previousMonth :
                    this.previousMonthTotalCosts[salesRep] += parseFloat(sale.cost_amount);
                    this.previousMonthTotalPrices[salesRep] += parseFloat(sale.price_amount);
                    break;
                }
              }
            }
          })
      }.bind(this))
    api({ endpoint: `${SALES_REPS_ENDPOINT}` })
      .then(function (res) {
          this.salesReps = res.data
      }.bind(this))
  },
  methods: {
    formatValue: formatValue,
    toLowerCase: toLowerCase,
    idsForBulkActionUpdate(idForBulkActionKeyValue) {
      Object.keys(idForBulkActionKeyValue).forEach(key => {
          if (idForBulkActionKeyValue[key]) {
              this.idsForBulkAction.push(key)
          } else {
            this.idsForBulkAction = this.idsForBulkAction.filter(id => id !== key)
          }
      })
    },
    applyBulkAction() {
      if( this.bulkAction !== '' ) {
        this.spinners['bulk-actions'] = true
        this.salesBulkActionError = false
        this.salesBulkActionErrorMessage = ''

        const self = this
        switch( this.bulkAction ) {
          case "delete":

            api({ endpoint: `${SALES_ENDPOINT}`,
                method: 'DELETE',
                data: {ids: this.idsForBulkAction},
                addTokenToHeaders: true
            })
              .then(function (res) {
                if (!res.data || res.data.status !== 'success') {
                  this.salesBulkActionError = true
                  this.salesBulkActionErrorMessage = 'Error in sales bulk action'
                } else {
                  this.sales = this.sales.filter(s => this.idsForBulkAction.indexOf(s.id.toString()) < 0)
                  this.filterSales()

                  this.idsForBulkAction = []
                  this.spinners['bulk-actions'] = false
                }
              }.bind(this),
              function(error) {
                self.spinners['bulk-actions'] = false
                self.salesBulkActionError = true
                self.salesBulkActionErrorMessage = 'Error: ' + error
              })

            break;
        }
      }
    },
    addSalesRep() {
        this.spinners['add-sales-rep'] = true
        this.error = ''

        const newSalesRepData = {
            firstname: this.repFirstname,
            lastname: this.repLastname
        }
        api({
            endpoint: `${SALES_REPS_ENDPOINT}`,
            method: 'POST',
            data: newSalesRepData,
            addTokenToHeaders: true
        })
          .then(function (res) {
              if (res.data.status !== 'fail') {
                const newSalesRep = res.data
                this.salesReps.push(newSalesRep)
                this.repFirstname = ''
                this.repLastname = ''
              } else {
                this.error = res.data.message
              }
              this.spinners['add-sales-rep'] = false
          }.bind(this))
    },
    customFilterByProfitAmount (input, param) {

      switch (param) {
        case '25':
          if ( input>= 1.25 ) return true;
          break;
        case '10':
          if ( input > 1.1 && input < 1.25 ) return true;
          break;
        case '5':
          if ( input <= 1.1 ) return true;
          break;
        default:
          return false;
      }
    },
    filterSales() {
      this.filteredSales = []

      if (
        (
          this.filterSalesBySalesRep != null
          &&
          this.filterSalesBySalesRep !== ''
        )
        ||
        (
          this.filterSalesByProfitAmount != null
          &&
          this.filterSalesByProfitAmount !== ''
        )
        ||
        (
          this.filterSalesByMonth != null
          &&
          this.filterSalesByMonth !== ''
        )
        ||
        (
          this.salesDisplay === 'all'
        )
      ) {
        let filterMonth;
        let filterYear;
        if(
          this.filterSalesByMonth != null
          &&
          this.filterSalesByMonth !== ''
        ) {
          const filterDateParams = this.filterSalesByMonth.split('-');

          filterMonth = filterDateParams[0];
          filterYear = filterDateParams[1];
        }

        this.totalCostAmount = 0;
        this.totalPriceAmount = 0;
        this.totalProfitAmount = 0;

        for (let i = 0; i < this.sales.length; i++) {
          const dateParams = this.sales[i].date.split('-');
          const saleDate = new Date(dateParams[0], parseInt(dateParams[1]) - 1, dateParams[2]);

          const saleMonth = saleDate.getMonth();
          const saleYear = saleDate.getYear() + 1900;

          if (
            (
              (
                this.filterSalesBySalesRep == null
                ||
                this.filterSalesBySalesRep === ''
              )
              ||
              (
              this.sales[i].device != null &&
              this.sales[i].device.sales_rep === this.filterSalesBySalesRep
              )
            )
            &&
            (
              (
                this.filterSalesByProfitAmount == null
                ||
                this.filterSalesByProfitAmount === ''
              )
              ||
              (
                this.sales[i].cost_amount !== 0 &&
                this.customFilterByProfitAmount(this.sales[i].price_amount/this.sales[i].cost_amount, this.filterSalesByProfitAmount) === true
              )
            )
            &&
            (
              (
                this.filterSalesByMonth == null
                ||
                this.filterSalesByMonth === ''
              )
              ||
              (
                filterMonth.toString() === saleMonth.toString()
                  &&
                filterYear.toString() === saleYear.toString()
              )
            )
          ) {

            this.totalCostAmount += Math.round(this.sales[i].cost_amount * 100) / 100;
            this.totalPriceAmount += Math.round(this.sales[i].price_amount * 100) / 100;
            this.filteredSales.push(this.sales[i]);
          }
        }

      } else {
        this.filteredSales = this.sales;
      }

       this.paginatedSales = paginateRecords(this.filteredSales, this.recordsPerPage)
    },
    deleteSalesRep: function(index) {
        this.salesReps.splice(index, 1);
    }
  }
}
</script>