<template>
  <tr>
    <td>{{ contactName }}</td>
    <td>
      <input type="text"
             class="form-control"
             v-model="clientBankDetails.routing_no"
             v-bind:class="{'is-invalid': hasErrors['routing_no']}"
      />
      <small class="text-danger">
        {{ hasErrors['routing_no'] }}
      </small>
    </td>
    <td>
      <input type="text"
             class="form-control"
             v-model="clientBankDetails.bank_account"
             v-bind:class="{'is-invalid': hasErrors['bank_account']}"
      />
      <small class="text-danger">
        {{ hasErrors['bank_account'] }}
      </small>
    </td>
    <td>
      <input type="text"
             class="form-control"
             v-model="clientBankDetails.credit_card_no"
             v-bind:class="{'is-invalid': hasErrors['credit_card_no']}"
      />
      <small class="text-danger">
        {{ hasErrors['credit_card_no'] }}
      </small>
    </td>
    <td>
      <input type="text"
             class="form-control"
             v-model="clientBankDetails.date_of_expiration"
             v-bind:class="{'is-invalid': hasErrors['date_of_expiration']}"
      />
      <small class="text-danger">
        {{ hasErrors['date_of_expiration'] }}
      </small>
    </td>
    <td>
      <select class="form-control"
              v-model="clientBankDetails.select_first_contact"
      >
        <option :value="true">Yes</option>
        <option :value="false">No</option>
      </select>
    </td>
    <td>
      <select class="form-control"
              v-model="clientBankDetails.add_ach"
      >
        <option :value="true">Yes</option>
        <option :value="false">No</option>
      </select>
    </td>
    <td>
      <select class="form-control"
              v-model="clientBankDetails.add_authorize_net"
      >
        <option :value="true">Yes</option>
        <option :value="false">No</option>
      </select>
    </td>
    <td>
      <action-button icon="fa-save"
                     text="Save"
                     :spin="spinners[paymentInfo.id]"
                     :disabled="spinners[paymentInfo.id]"
                     v-on:click="updatePaymentInfo(paymentInfo)"
      ></action-button>
    </td>
  </tr>
</template>

<script>
  import api from "../../services/api";

  const PAYMENT_INFOS_ENDPOINT = 'payment_infos'
  export default {
    props: {
      paymentInfo: {
        type: Object,
        required: true
      }
    },
    data: () => ({
      contactName: '',
      clientBankDetails: {},
      hasErrors: {},
      spinners: {}
    }),
    created: function() {
      this.contactName = this.paymentInfo.client ? this.paymentInfo.client.contact_name : ''
      this.clientBankDetails = {
        routing_no: this.paymentInfo.routing_no,
        bank_account: this.paymentInfo.bank_account,
        credit_card_no: this.paymentInfo.credit_card_no,
        date_of_expiration: this.paymentInfo.date_of_expiration,
        select_first_contact: this.paymentInfo.select_first_contact,
        add_ach: this.paymentInfo.add_ach,
        add_authorize_net: this.paymentInfo.add_authorize_net
      }
    },
    methods: {
      updatePaymentInfo(paymentInfo) {
        this.spinners[paymentInfo.id] = true
        this.hasErrors = {}

        api({
            endpoint: `${PAYMENT_INFOS_ENDPOINT}/${this.paymentInfo.id}`,
            method: 'PUT',
            data: this.clientBankDetails,
            addTokenToHeaders: true
        })
          .then(function (res) {
            if (res.data.errors && Object.keys(res.data.errors).length > 0) {
              Object.keys(res.data.errors).forEach(fieldName => {
                this.hasErrors[fieldName] = res.data.errors[fieldName]
              })
            }
            this.spinners[paymentInfo.id] = false
          }.bind(this))
      }
    }
  }
</script>