<template>

		<div v-if="devicesAwaitingPriceApproval.length > 0">

      <h2-title :title="'Price Changes'"></h2-title>

			<div class="alert alert-danger">
				<strong>
          There are devices for which price changes should be reviewed. Please check the panel below.
        </strong>
			</div>

			<div class="card">
				<div class="card-header bg-danger">
					Review price changes
				</div>
				<div class="card-body">
					<table class="table table-striped">
						<thead>
							<tr>
								<th>Client</th>
								<th>Make</th>
								<th>Model</th>
								<th>S/N</th>
								<th>Old prices</th>
								<th>New prices</th>
								<th>&nbsp;</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="device in devicesAwaitingPriceApproval" :key="device.id">
								<td>
									<span v-if="device.client && device.client.contact_name">
										{{ device.client.contact_name }}
									</span>
								</td>
								<td>{{ device.maker }}</td>
								<td>{{ device.model }}</td>
								<td>{{ device.serial_number }}</td>
								<td>
									Base price: <b>{{ device.base_price }}</b>
									<br>Mono price: <b>{{ device.mono_price }}</b>
									<br>Color price: <b>{{ device.color_price }}</b>
									<br>Monocolor price: <b>{{ device.monocolor_price }}</b>
									<br>Color2 price: <b>{{ device.color2_price }}</b>
									<br>Color3 price: <b>{{ device.color3_price }}</b>
								</td>
								<td>
									Base price: <b>{{ formatValue(device.base_price + device.base_price*(device.escalation_calculated_value/100)) }}</b>
									<br>Mono price: <b>{{ formatValue(device.mono_price + device.mono_price*(device.escalation_calculated_value/100)) }}</b>
									<br>Color price: <b>{{ formatValue(device.color_price + device.color_price*(device.escalation_calculated_value/100)) }}</b>
									<br>Monocolor price: <b>{{ formatValue(device.monocolor_price + device.monocolor_price*(device.escalation_calculated_value/100)) }}</b>
									<br>Color2 price: <b>{{ formatValue(device.color2_price + device.color2_price*(device.escalation_calculated_value/100)) }}</b>
									<br>Color3 price: <b>{{ formatValue(device.color3_price + device.color3_price*(device.escalation_calculated_value/100)) }}</b>
								</td>
								<td>
									<div class="mb-3">
										<action-button type="primary"
																		icon="fa-check"
																		text="Approve"
																		:spin="spinners['approve-' + device.id]"
																		:disabled="spinners['approve-' + device.id]"
																		v-on:click="approveDevicePricesUpdates(device)"
										></action-button>
									</div>
									<div>
										<action-button type="danger"
																		icon="fa-times"
																		text="Reject"
																		:spin="spinners['reject-' + device.id]"
																		:disabled="spinners['reject-' + device.id]"
																		v-on:click="rejectDevicePricesUpdates(device)"
										></action-button>
									</div>
								</td>
							</tr>
						</tbody>
					</table>

				</div>
			</div>

		</div>

    <h2-title :title="'Devices'"></h2-title>

    <dashboard-spinner v-if="devices.length === 0"></dashboard-spinner>

    <div v-if="devices.length > 0">
      <div class="row mb-3">
        <div class="col-sm-12">
          <h6><b>Filters:</b></h6>
        </div>

        <div class="col-sm-4">
          By sales rep:<br>
          <select class="form-control"
                  v-model="filterDevicesBySalesRep"
                  @change="filterDevices()"
          >
            <option value="">
              All
            </option>
            <option v-for="salesRep in salesReps"
                    :value="toLowerCase(salesRep.firstname)"
                    :key="salesRep.id"
            >
              {{ salesRep.firstname }} {{ salesRep.lastname }}
            </option>
          </select>
        </div>

        <div class="col-sm-4">
          By customer:<br>
          <select class="form-control"
                  v-model="filterDevicesByClient"
                  @change="filterDevices()"
          >
            <option value="">All</option>
            <option v-for="client in clients"
                    :value="client.id"
                    :key="client.id"
            >
              {{ client.contact_name }}
            </option>
          </select>
        </div>

        <div class="col-sm-4">
          By serial number:<br>
          <input type="text"
                 class="form-control"
                 v-model="filterDevicesBySerialNumber"
                 v-on:input="filterDevices()"
          />
        </div>
      </div>

      <div class="table-responsive">

        <pagination v-model="currentPage"
                    :records="filteredDevices.length"
                    :per-page="recordsPerPage"
        />

        <table class="table table-success table-striped table-sm">
          <thead>
            <tr>
              <th>ID</th>
              <th>Maker/Model</th>
              <th>Serial Number</th>
              <th>Added on</th>
            </tr>
          </thead>

					<tbody v-for="device in paginatedDevices[currentPage - 1]" :key="device.id">
            <device-row :device='device'
                        :uniqueIndex='device.id'
                        :salesReps='salesReps'
                        :clients='clients'
                        :taxes='taxes'
                        :showDeleteButton="true"
                        v-on:delete-device="deleteDevice(device)"
            ></device-row>
          </tbody>

        </table>
      </div>
    </div>

</template>

<script>
import DeviceRow from './rows/DeviceRow.vue'
import api from "../services/api";
import {formatValue, paginateRecords, toLowerCase} from "../services/helpers";

const DEVICES_ENDPOINT = 'devices/'
const CLIENTS_ENDPOINT = 'clients/'
const SALES_REPS_ENDPOINT = 'sales_reps/'
const TAXES_ENDPOINT = 'taxes/'

export default {
  components: {
    DeviceRow
  },
  data: () => ({
    filterDevicesBySalesRep: '',
    filterDevicesByClient: '',
    filterDevicesBySerialNumber: '',
    salesReps: [],
    currentPage: 1,
    recordsPerPage: 10,
    priceFields: [
        {field: 'base', title: 'Base', hasPrints: false},
        {field: 'mono_price', title: 'Mono cost', hasPrints: true},
        {field: 'color_price', title: 'Color cost', hasPrints: true},
        {field: 'monocolor_price', title: 'Mono/color cost', hasPrints: true},
        {field: 'color2_price', title: 'Color2 cost', hasPrints: true},
        {field: 'color3_price', title: 'Color3 cost', hasPrints: true}
    ],
    clients: [],
    devices: [],
    devicesAwaitingPriceApproval: [],
    taxes: [],
    filteredDevices: [],
    paginatedDevices: [],
		spinners: {}
  }),
  created: function() {
    api({ endpoint: `${CLIENTS_ENDPOINT}` })
      .then(function (res) {
          this.clients = res.data
      }.bind(this))
    api({ endpoint: `${DEVICES_ENDPOINT}` })
      .then(function (res) {
          this.devices = res.data
          this.filteredDevices = res.data
          this.paginatedDevices = paginateRecords(this.filteredDevices, this.recordsPerPage)

					this.devicesAwaitingPriceApproval = this.devices.filter(d => {
						return d.escalation_status === 'pending' && d.escalation_calculated_value > 0;
					})
      }.bind(this))
    api({ endpoint: `${SALES_REPS_ENDPOINT}` })
      .then(function (res) {
          this.salesReps = res.data
      }.bind(this))
    api({ endpoint: `${TAXES_ENDPOINT}` })
      .then(function (res) {
          this.taxes = res.data
      }.bind(this))
  },
  methods: {
    formatValue: formatValue,
    toLowerCase: toLowerCase,
		approveDevicePricesUpdates(device) {
			this.spinners['approve-' + device.id] = true

			const deviceData = {}
			deviceData['escalation_status'] = null
			deviceData['escalation_calculated_value'] = null

			const escalationValue = device.escalation_calculated_value
			deviceData['base_price'] = device.base_price + device.base_price*escalationValue/100
			deviceData['mono_price'] = device.mono_price + device.mono_price*escalationValue/100
			deviceData['color_price'] = device.color_price + device.color_price*escalationValue/100
			deviceData['monocolor_price'] = device.monocolor_price + device.monocolor_price*escalationValue/100
			deviceData['color2_price'] = device.color2_price + device.color2_price*escalationValue/100
			deviceData['color3_price'] = device.color3_price + device.color3_price*escalationValue/100

			api({
					endpoint: `${DEVICES_ENDPOINT}${device.id}`,
					method: 'PUT',
					data: deviceData,
					addTokenToHeaders: true
			})
				.then(function (res) {
					console.log(res)
					this.spinners['approve-' + device.id] = false
					this.devicesAwaitingPriceApproval = this.devicesAwaitingPriceApproval.filter(d => {
						return d.id !== device.id
					})
				}.bind(this))
		},
		rejectDevicePricesUpdates(device) {
			this.spinners['reject-' + device.id] = true

			const deviceData = {}
			deviceData['escalation_status'] = null
			deviceData['escalation_calculated_value'] = 1

			api({
					endpoint: `${DEVICES_ENDPOINT}${device.id}`,
					method: 'PUT',
					data: deviceData,
					addTokenToHeaders: true
			})
				.then(function (res) {
					console.log(res)
					this.spinners['reject-' + device.id] = false
					this.devicesAwaitingPriceApproval = this.devicesAwaitingPriceApproval.filter(d => {
						return d.id !== device.id
					})
				}.bind(this))
		},
    filterDevices() {
      this.filteredDevices = []

      if (
          this.filterDevicesBySalesRep !== ''
          ||
          this.filterDevicesByClient !== ''
          ||
          this.filterDevicesBySerialNumber
      ) {
        for (var i = 0; i < this.devices.length; i++) {
          if (
            (
              typeof this.filterDevicesBySerialNumber == 'undefined'
              ||
              this.filterDevicesBySerialNumber === ''
              ||
              this.devices[i].serial_number.toLowerCase().indexOf(
                  this.filterDevicesBySerialNumber.toLowerCase()
              ) !== -1
            )
            &&
            (
              this.filterDevicesBySalesRep === ''
              ||
              this.devices[i].sales_rep === this.filterDevicesBySalesRep
            )
            &&
            (
              this.filterDevicesByClient === ''
              ||
              this.devices[i].client_id === this.filterDevicesByClient
            )
          )  {
              this.filteredDevices.push(this.devices[i]);
          }
        }
      } else {
        this.filteredDevices = this.devices;
      }

      this.paginatedDevices = paginateRecords(this.filteredDevices, this.recordsPerPage)
    },
    deleteDevice: function(device) {
      const index = this.findWithAttr(this.devices, 'id', device.id)

      if (index >= 0) {
        this.devices.splice(index, 1)
        this.filterDevices()
      }
    },
    findWithAttr(array, attr, value) {
      for(var i = 0; i < array.length; i += 1) {
          if(array[i][attr] === value) {
              return i
          }
      }
      return -1
    }
  }
}
</script>