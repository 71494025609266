<template>
  <tr>
    <td>
      <a :href="fileStorageDir + file.type + '/' + file.filename"
         target="_blank"
         download
      >
        {{ file.filename }}
      </a>
    </td>
    <td>{{ formatDateTime(file.created_at) }}</td>
  </tr>
</template>

<script>
  import {formatDateTime} from "../../services/helpers";

  export default {
    props: {
      file: {}
    },
    data: () => ({
      fileStorageDir: process.env.VUE_APP_FILE_STORAGE_DIR
    }),
    methods: {
      formatDateTime: formatDateTime
    }
  }
</script>