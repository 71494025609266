<template>

  <h2-title :title="'Client CC/bank account information'"></h2-title>

  <dashboard-spinner v-if="paymentInfos.length === 0"></dashboard-spinner>

  <div class="table-responsive" v-if="paymentInfos.length > 0">

    <pagination v-model="paymentInfosCurrentPage"
                :records="paymentInfos.length"
                :per-page="recordsPerPage"
    />

    <table class="table table-striped table-sm">
      <thead>
        <tr>
          <th>Client</th>
          <th>Routing No.</th>
          <th>Bank Account</th>
          <th>Credit Card no.</th>
          <th>Date of CC expiration</th>
          <th>Select First Contact</th>
          <th>Create ACH File</th>
          <th>Create Authorize.net File</th>
          <th class="save-button-col">&nbsp;</th>
        </tr>
      </thead>

      <tbody>

        <payment-info-row v-for="paymentInfo in filteredPaymentInfos[paymentInfosCurrentPage - 1]"
                    :key="paymentInfo.id"
                    :paymentInfo="paymentInfo"
        ></payment-info-row>

      </tbody>

    </table>
  </div>

  <h2-title :title="'ACH Files'"></h2-title>

  <dashboard-spinner v-if="paymentFiles.length === 0"></dashboard-spinner>

  <div class="table-responsive" v-if="paymentFiles.length > 0">

    <pagination v-model="achFilesCurrentPage"
                :records="sortedPaymentFiles['ach'].length"
                :per-page="recordsPerPage"
    />

    <table class="table table-striped table-sm">
      <thead>
        <tr>
          <th>File link</th>
          <th>Created On</th>
        </tr>
      </thead>

      <tbody>
        <payment-file-row v-for="file in filteredPaymentFiles['ach'][achFilesCurrentPage - 1]"
                          :key="file.id"
                          :file='file'
        ></payment-file-row>
      </tbody>

    </table>
  </div>

  <h2-title :title="'Authorize.net Files'"></h2-title>

  <dashboard-spinner v-if="paymentFiles.length === 0"></dashboard-spinner>

  <div class="table-responsive" v-if="paymentFiles.length > 0">

    <pagination v-model="authorizeNetFilesCurrentPage"
                :records="sortedPaymentFiles['authorize_net'].length"
                :per-page="recordsPerPage"
    />

    <table class="table table-striped table-sm">
      <thead>
        <tr>
          <th>Filename</th>
          <th>Created On</th>
        </tr>
      </thead>

      <tbody>

        <payment-file-row v-for="file in filteredPaymentFiles['authorize_net'][authorizeNetFilesCurrentPage - 1]"
                          :key="file.id"
                          :file='file'
        ></payment-file-row>
      </tbody>

    </table>
  </div>

</template>

<script>
import PaymentInfoRow from "./rows/PaymentInfoRow";
import PaymentFileRow from "./rows/PaymentFileRow";
import api from "../services/api";
import {paginateRecords} from "../services/helpers";

const PAYMENT_INFOS_ENDPOINT = 'payment_infos/'
const PAYMENT_FILES_ENDPOINT = 'payment_files/'

export default {
  components: {
      PaymentInfoRow,
      PaymentFileRow
  },
  data: () => ({
    achFilesCurrentPage: 1,
    authorizeNetFilesCurrentPage: 1,
    paymentInfosCurrentPage: 1,
    recordsPerPage: 10,
    paymentInfos: [],
    paymentFiles: [],
    sortedPaymentFiles: {
        'ach': [],
        'authorize_net': []
    },
    filteredPaymentInfos: {
    },
    filteredPaymentFiles: {
        'ach': [],
        'authorize_net': []
    }
  }),
  created: function() {
    api({ endpoint: `${PAYMENT_INFOS_ENDPOINT}` })
      .then(function (res) {
          this.paymentInfos = res.data
          this.filteredPaymentInfos = paginateRecords(this.paymentInfos, this.recordsPerPage)
      }.bind(this))
    api({ endpoint: `${PAYMENT_FILES_ENDPOINT}` })
      .then(function (res) {
          this.paymentFiles = res.data
          this.sortedPaymentFiles['ach'] = this.paymentFiles.filter(f => f.type && f.type === 'ach')
          this.sortedPaymentFiles['authorize_net'] = this.paymentFiles.filter(f => f.type && f.type === 'authorize_net')
          this.filteredPaymentFiles['ach'] =
              paginateRecords(this.sortedPaymentFiles['ach'], this.recordsPerPage)
          this.filteredPaymentFiles['authorize_net'] =
              paginateRecords( this.sortedPaymentFiles['authorize_net'], this.recordsPerPage)
      }.bind(this))
  }
}
</script>