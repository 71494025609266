<template>

    <h2-title :title="'Invoices'"></h2-title>

    <dashboard-spinner v-if="templates.length === 0"></dashboard-spinner>

    <div class="row" v-if="templates.length > 0">

      <div class="col-sm-3 pr-3">

        <error v-if="invoicesUploadError">
          {{ invoicesUploadErrorMessage }}
        </error>

        <div class="dropdown mb-3">
          <button class="btn btn-secondary dropdown-toggle"
                  type="button"
                  id="selectTemplatesButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
          >
            <span v-if="!selectedTemplateTitle">Select Excel spreadsheet template</span>
            <span v-if="selectedTemplateTitle">{{ selectedTemplateTitle }}</span>
          </button>
          <div class="dropdown-menu" aria-labelledby="selectTemplatesButton">
            <a class="dropdown-item"
               href="#"
               v-for="template in templates"
               :key="template.id"
               @click.prevent="selectTemplate(template)"
            >
              {{ template.title }}
            </a>
          </div>
        </div>

        <div class="mb-3">
          <label  type="button"
                  class="btn btn-primary mr-3"
          >
            Select File
            <input ref="file"
                   type="file"
                   class="file-upload-input"
                   :accept="acceptedFileTypes"
                   @change="changeInvoiceFile"
            />
          </label>
          <span v-if="invoiceFile">{{ invoiceFile.name }}</span>
        </div>

        <div class="mb-3"
             v-if="selectedTemplateTitle && invoiceFile"
        >
          <action-button icon="fa-upload"
                         text="Upload invoice"
                         :spin="spinners['upload-invoice']"
                         :disabled="spinners['upload-invoice']"
                         v-on:click="uploadInvoice()"
          ></action-button>
        </div>

      </div>
      <div class="col-sm-9 pl-3">

        <div class="mb-3">
          View invoice templates:
        </div>

        <template-row v-for="(template, index) in templates"
                      :key="template.id"
                      :template='template'
                      v-on:delete-template="deleteTemplate(index)"
        ></template-row>

        <error v-if="templateAddError">
          {{ templateAddErrorMessage }}
        </error>

        <div class="row">
          <div class="col-sm-9">

            <div class="card">
              <div class="card-header">
                Add new template
              </div>
              <ul class="list-group list-group-flush">
                <li class="list-group-item">

                  <div class="form-inline">
                    <div class="form-group pr-3">
                      <label for="newTemplateTitle" class="pr-3">
                        <b>Heading:</b>
                      </label>
                      <input type="text"
                             class="form-control"
                             id="newTemplateTitle"
                             v-model="newTemplateTitle"
                      />
                    </div>

                    <action-button icon="fa-check"
                                   text="Add"
                                   :spin="spinners['add-template']"
                                   :disabled="newTemplateTitle === '' || spinners['add-template']"
                                   v-on:click="addTemplate()"
                    ></action-button>

                  </div>

                </li>
              </ul>
            </div>

          </div>
        </div>

      </div>
    </div>

    <h2-title :title="'Upload logs'"></h2-title>

    <dashboard-spinner v-if="uploadLogs.length === 0"></dashboard-spinner>

    <div v-if="uploadLogs.length > 0">
      <pagination v-model="currentUploadLogsPage"
                  :records="uploadLogs.length"
                  :per-page="recordsPerPage"
      />

      <div v-for="uploadLog in filteredUploadLogs[currentUploadLogsPage - 1]"
           :key="uploadLog.id"
      >
        <upload-log-row :uploadLog='uploadLog'></upload-log-row>
      </div>
    </div>

</template>


<script>
import api from '../services/api'
import { invoicesApi } from '../services/api'
import TemplateRow from "./rows/TemplateRow";
import UploadLogRow from "./rows/UploadLogRow";
import {paginateRecords} from "../services/helpers";

const INVOICES_ENDPOINT = 'invoices/';
const TEMPLATES_ENDPOINT = 'templates/';
const UPLOAD_LOGS_ENDPOINT = 'upload_logs/';

export default {
  components: {
    TemplateRow,
    UploadLogRow
  },
  data: () => ({
    acceptedFileTypes: ".csv, .xls, .xlsx",
    currentUploadLogsPage: 1,
    filteredUploadLogs: [],
    invoiceFile: '',
    invoiceFileName: '',
    newTemplateTitle: '',
    recordsPerPage: 10,
    selectedTemplateId: null,
    selectedTemplateTitle: '',
    spinners: {},
    templates: [],
    uploadLogs: [],
    invoicesUploadError: false,
    invoicesUploadErrorMessage: '',
    templateAddError: false,
    templateAddErrorMessage: ''
  }),
  created: function() {
    api({ endpoint: `${TEMPLATES_ENDPOINT}` })
      .then(function (res) {
          this.templates = res.data
      }.bind(this))
    api({ endpoint: `${UPLOAD_LOGS_ENDPOINT}` })
      .then(function (res) {
          this.uploadLogs = res.data
          this.filteredUploadLogs = paginateRecords(this.uploadLogs, this.recordsPerPage)
      }.bind(this))
  },
  methods: {
    addTemplate() {
        this.spinners['add-template'] = true
        this.templateAddError = false
        this.templateAddErrorMessage = ''

        const newTemplateData = {
            title: this.newTemplateTitle
        }
        const self = this
        api({
            endpoint: `${TEMPLATES_ENDPOINT}`,
            method: 'POST',
            data: newTemplateData,
            addTokenToHeaders: true
        })
          .then(function (res) {
              if (res.data.status !== 'fail') {
                const newTemplate = res.data
                this.templates.push(newTemplate)
                this.newTemplateTitle = ''
              } else {
                this.templateAddError = true
                this.templateAddErrorMessage = 'Error adding a template'
              }
              this.spinners['upload-invoice'] = false
          }.bind(this),
          function(error) {
            self.spinners['upload-invoice'] = false
            self.templateAddError = true
            self.templateAddErrorMessage = 'Error: ' + error
          })
    },
    deleteTemplate: function(index) {
      this.templates.splice(index, 1);
    },
    selectTemplate(template) {
      this.selectedTemplateId = template.id
      this.selectedTemplateTitle = template.title
    },
    changeInvoiceFile(event) {
      if (event.target.files && event.target.files[0]) {
          this.invoiceFile = this.$refs.file.files[0]
      }
    },
    uploadInvoice() {
        this.spinners['upload-invoice'] = true
        this.invoicesUploadError = false
        this.invoicesUploadErrorMessage = ''

        let formData = new FormData();
        formData.append('invoiceFile', this.invoiceFile);
        formData.append('selectedTemplateId', JSON.stringify(this.selectedTemplateId));

        const self = this
        invoicesApi({
            endpoint: `${INVOICES_ENDPOINT}`,
            method: 'POST',
            data: formData,
            addTokenToHeaders: true
        })
          .then(function (res) {
            const newDevicesCount = res.data ? res.data.new_devices_count : 0

            if (newDevicesCount > 0) {
                const newDevices = res.data ? res.data.new_devices : []
                this.$router.replace({name:'add-devices', params:{newDevices}})
            } else {
              const devicesGroupedByClient = res.data ? res.data.devices_grouped_by_client : {}

              if (Object.keys(devicesGroupedByClient).length > 0) {
                const encodedDevices = JSON.stringify(devicesGroupedByClient)
                this.$router.replace({name:'confirm-invoices', params:{encodedDevices}})
              } else {
                this.invoicesUploadError = true
                this.invoicesUploadErrorMessage = 'Error uploading invoices'
              }
            }
            this.spinners['upload-invoice'] = false
          }.bind(this),
          function(error) {
            self.spinners['upload-invoice'] = false
            self.invoicesUploadError = true
            self.invoicesUploadErrorMessage = 'Error: ' + error
          })

    }
  }
}
</script>