<template>
  <div class="accordion mb-3"
       :id="'accordion_' + uploadLog.id"
  >
    <div class="card">
      <div class="card-header custom-accordion"
           :id="'heading_' + uploadLog.id"
      >
        <h2 class="mb-0">
          <button class="btn btn-link btn-block text-left collapsed"
                  type="button"
                  data-toggle="collapse"
                  :data-target="'#collapse_' + uploadLog.id"
                  aria-expanded="false"
                  :aria-controls="'collapse_' +  uploadLog.id"
          >
            {{ uploadLog.filename }} / Errors: {{ uploadLog.errors }}
          </button>
        </h2>
      </div>
      <div :id="'collapse_' + uploadLog.id"
           class="collapse"
           :aria-labelledby="'heading_' + uploadLog.id"
           :data-parent="'#accordion_' + uploadLog.id"
      >
        <div class="card-body file-content-wrapper" v-html="fileContent"></div>
      </div>
    </div>
  </div>
</template>

<script>
  import {fileApi} from "../../services/api";

  export default {
    props: {
      uploadLog: {}
    },
    data: () => ({
      fileContent: ''
    }),
    created: function() {
      const filename = 'backend/storage/files/upload_logs/' + this.uploadLog.filename

      fileApi({ endpoint: filename })
        .then(function (res) {
          this.fileContent = res.data
        }.bind(this))
    }
  }
</script>