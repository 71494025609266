<template>

  <h2-title :title="'Sending Invoices'"></h2-title>

  <span v-if="continueFlag">Sending invoices in the background...</span>

  <error v-if="sendInvoicesError">
    {{ sendInvoicesErrorMessage }}
  </error>

  <div class="mb-3 mt-3" v-if="responseErrorMessagesBlock.length > 0">
    <br><b class="error">There were errors during upload:</b>
    <div v-for="responseErrorMessage in responseErrorMessagesBlock"
         :key="responseErrorMessage.client_contact_name"
    >
      [ {{ formatDateTime(responseErrorMessage.datetime) }} ] Error: <b>{{ responseErrorMessage.message }}</b>
      Client name: <b>{{ responseErrorMessage.client_contact_name }}</b>
    </div>
  </div>

  <div class="mt-3 mb-3" v-if="responseMessagesBlock.length > 0">
    <div v-for="responseMessage in responseMessagesBlock"
         :key="responseMessage.invoice_number"
    >
      [ {{ formatDateTime(responseMessage.datetime) }} ]
      Invoice #<b>{{ responseMessage.invoice_number }}</b> was created successfully.
      Client name: <b>{{ responseMessage.client_contact_name }}</b>
    </div>
  </div>

  <div class="mt-3 mb-3" v-if="achFilesResponse['message']">
    <b>{{ achFilesResponse['message'] }}</b>
    <br>
    <a :href="baseUrl + 'backend/storage/files/ach/' + achFilesResponse['link']"
        target="_blank"
    >
      File link
    </a>
  </div>
  <div class="mt-3 mb-3" v-if="authorizeNetFilesResponse['message']">
    <b>{{ authorizeNetFilesResponse['message'] }}</b>
    <br>
    <a :href="baseUrl + 'backend/storage/files/authorize_net/' + authorizeNetFilesResponse['link']"
        target="_blank"
    >
      File link
    </a>
  </div>
  <div class="mt-3 mb-3" v-if="uploadLogsFilesResponse['message']">
    <b>{{ uploadLogsFilesResponse['message'] }}</b>
    <br>
    <a :href="baseUrl + 'backend/storage/files/upload_logs/' + uploadLogsFilesResponse['link']"
        target="_blank"
    >
      File link
    </a>
  </div>

</template>

<script>

import {formatDateTime} from "../services/helpers";
import api from "../services/api";
const INVOICES_ENDPOINT = 'invoices/';

export default {
    props: ['devicesGroupedByClient'],
    components: {},
    data: () => ({
      responseErrorMessagesBlock: [],
      responseMessagesBlock: [],
      achFilesResponse: {
        link: '',
        message: ''
      },
      authorizeNetFilesResponse: {
        link: '',
        message: ''
      },
      uploadLogsFilesResponse: {
        link: '',
        message: ''
      },
      sendInvoicesError: false,
      sendInvoicesErrorMessage: '',
      continueFlag: true,
      baseUrl: process.env.VUE_APP_BASE_DOMAIN
    }),
    created: function() {
      if (this.devicesGroupedByClient) {
        this.sendInvoice(0)
      } else {
        this.$router.replace({name:'invoices'})
      }
    },
    methods: {
      formatDateTime: formatDateTime,
      sendInvoice(
          batchIndex,
          achFileName = '',
          authorizeNetFileName = '',
          uploadLogsFileName = '',
          uploadErrorsCount = 0
      ) {
        this.sendInvoicesError = false
        this.sendInvoicesErrorMessage = ''

        const devicesGroupedByClient = JSON.parse(this.devicesGroupedByClient)

        const data = {
            devices_grouped_by_client: devicesGroupedByClient,
            batch_index: batchIndex,
            ach_filename: achFileName,
            authorize_net_filename: authorizeNetFileName,
            upload_logs_filename: uploadLogsFileName,
            upload_logs_file_errors_count: uploadErrorsCount
        }
        api({
            endpoint: `${INVOICES_ENDPOINT}`,
            method: 'PUT',
            data: data,
            addTokenToHeaders: true
        })
          .then(function (res) {

              let achFileIsCreated = ''
              let authorizeNetFileIsCreated = ''
              let achFileName = ''
              let authorizeNetFileName = ''
              let uploadLogsFileName = ''
              let uploadErrorsCount = 0
              let messages = {}

              if (res.data) {
                this.continueFlag = res.data.continue_flag

                achFileIsCreated = res.data.ach_file && res.data.ach_file != ''
                authorizeNetFileIsCreated = res.data.authorize_net_file && res.data.authorize_net_file != ''
                achFileName = res.data.ach_file
                authorizeNetFileName = res.data.authorize_net_file
                uploadLogsFileName = res.data.upload_logs_file
                uploadErrorsCount = res.data.upload_logs_file_errors_count
                messages = res.data.messages
              } else {
                this.sendInvoicesError = true
                this.sendInvoicesErrorMessage = 'Error in sending invoices'
              }

              Object.keys(messages).forEach(messageIndex => {
                const message = messages[messageIndex]
                switch(message.status) {
                  case "error":
                    this.responseErrorMessagesBlock.push(message);
                    break;
                  case "success":
                    this.responseMessagesBlock.push(message);
                    break;
                }
              })
              if (this.continueFlag) {
                batchIndex += 4
                this.sendInvoice(
                    batchIndex,
                    achFileName,
                    authorizeNetFileName,
                    uploadLogsFileName,
                    uploadErrorsCount
                )
              } else {
                if (achFileIsCreated) {
                  this.achFilesResponse['message'] = 'ACH file was created.';
                  this.achFilesResponse['link'] = achFileName;
                }
                if (authorizeNetFileIsCreated) {
                  this.authorizeNetFilesResponse['message'] = 'Authorize.net file was created.';
                  this.authorizeNetFilesResponse['link'] = authorizeNetFileName;
                }
                this.uploadLogsFilesResponse['message'] = 'Upload log file:';
                this.uploadLogsFilesResponse['link'] = uploadLogsFileName;
              }
          }.bind(this))
      }
    }
}

</script>