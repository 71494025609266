<template>

  <h2-title :title="'Correct and upload these devices'"></h2-title>

	<div v-if="filteredDevices && filteredDevices.length > 0">

		<pagination v-model="currentPage"
							:records="filteredDevices.length"
							:per-page="recordsPerPage"
		/>

		<table class="table table-success table-striped table-sm">
			<thead>
				<tr>
					<th>Maker/Model</th>
					<th>Serial Number</th>
				</tr>
			</thead>

			<tbody  v-for="(error, index) in errors" :key="index">
				<tr>
					<td colspan="4">
						<div class="alert alert-danger p-3 mb-0">
							<strong>{{ error.message }}</strong>
						</div>
					</td>

				</tr>
			</tbody>

			<tbody v-for="(device, index) in filteredDevices" :key="index">

				<input type="hidden"
							class="form-control"
							:value="device.make"
				/>
				<input type="hidden"
							class="form-control"
							:value="device.model"
				/>
				<input type="hidden"
							class="form-control"
							:value="device.serial_number"
				/>
				<input type="hidden"
							class="form-control"
							:value="device.contract_id"
				/>

				<device-row :device='JSON.parse(device)'
										:uniqueIndex='index'
										:salesReps='salesReps'
										:clients='clients'
										:taxes='taxes'
										:showDeleteButton="false"
										v-on:remove-device-id-from-new-devices="removeDeviceIdFromNewDevices(index)"
				></device-row>
			</tbody>

		</table>

	</div>

	<div v-if="showMessageAfterAllDevicesAreSaved">
		<div class="pt-3 pb-3">
			Now you can
			<router-link :to="'/invoices'">
			go back and upload an invoice or correct the devices information.
			</router-link>
		</div>
	</div>

</template>

<script>

import DeviceRow from "./rows/DeviceRow";
import api from "../services/api";
import {paginateRecords} from "../services/helpers";

const CLIENTS_ENDPOINT = 'clients/'
const SALES_REPS_ENDPOINT = 'sales_reps/'
const TAXES_ENDPOINT = 'taxes/'

export default {
		props: ['newDevices'],
		components: {
			DeviceRow
		},
    data: () => ({
			clients: [],
			currentPage: 1,
			filteredDevices: [],
			errors: [],
			paginatedDevices: [],
			recordsPerPage: 25,
			salesReps: [],
			spinners: {},
			taxes: [],
			showMessageAfterAllDevicesAreSaved: false,
			deviceData: {}
    }),
		created: function() {
			if (this.newDevices) {
				this.filteredDevices = this.newDevices
				this.paginatedDevices = paginateRecords(this.filteredDevices, this.recordsPerPage)
				api({ endpoint: `${CLIENTS_ENDPOINT}` })
					.then(function (res) {
							this.clients = res.data
					}.bind(this))
				api({ endpoint: `${SALES_REPS_ENDPOINT}` })
					.then(function (res) {
							this.salesReps = res.data
					}.bind(this))
				api({ endpoint: `${TAXES_ENDPOINT}` })
					.then(function (res) {
							this.taxes = res.data
					}.bind(this))
			} else {
				this.$router.replace({name:'invoices'})
			}
		},
    methods: {
      addDevice(index) {
				this.spinners['add-device-' + index] = true
      },
			removeDeviceIdFromNewDevices(deviceIndex) {
				const tempArray = JSON.parse(JSON.stringify(this.filteredDevices))

				tempArray.splice(deviceIndex, 1)

				this.filteredDevices = tempArray

				if (this.filteredDevices.length === 0) {
					this.showMessageAfterAllDevicesAreSaved = true
				}
			}
    }
}

</script>