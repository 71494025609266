<template>
  <nav id="sidebarMenu" class="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
    <div class="position-sticky pt-3">

      <ul class="nav flex-column">
        <menu-link href="/invoices" icon="home" :is-active="subIsActive('/invoices')">
          Invoices
        </menu-link>
        <menu-link href="/devices" icon="tool" :is-active="subIsActive('/devices')">
          Devices
        </menu-link>
        <menu-link href="/sales" icon="bar-chart-2" :is-active="subIsActive('/sales')">
          Sales
        </menu-link>
        <menu-link href="/payments" icon="edit" :is-active="subIsActive('/payments')">
          Bank Accounts/Payment Files
        </menu-link>
      </ul>

      <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
        <span>API</span>
      </h6>
      <ul class="nav flex-column mb-2">
        <api-link icon="fa-list-ul"
                  @linkClicked="updateClientRecords"
                  :showLoadingSpinner="showLoadingSpinner['update-clients']"
                  :caption="'(Can take up to 10 minutes)'"
        >
          Update Client List
        </api-link>
        <api-link icon="fa-money"
                  @linkClicked="updateTaxesRecords"
                  :showLoadingSpinner="showLoadingSpinner['taxes']"
        >
          Update Taxes List
        </api-link>
      </ul>

    </div>
  </nav>
</template>

<script>
import ApiLink from './ApiLink.vue'
import MenuLink from './MenuLink.vue'
import api from "../services/api";

const CLIENTS_ENDPOINT = 'clients/';
const TAXES_ENDPOINT = 'taxes/';

export default {
  components: {
    ApiLink,
    MenuLink
  },
  data: () => ({
    showLoadingSpinner: {
      'update-clients': {
        'loader': false,
        'success': false,
        'error': false
      },
      'taxes': {
        'loader': false,
        'success': false,
        'error': false
      }
    }
  }),
  methods: {
    subIsActive(input) {
      return this.$route.path === input
    },
    updateClientRecords() {
      this.showLoadingSpinner['update-clients']['loader'] = true

      api({ endpoint: `${CLIENTS_ENDPOINT}`, method: "POST", addTokenToHeaders: true })
        .then(function (res) {
            const responseStatus = res.data.status
            if (responseStatus === 'success') {
              this.showLoadingSpinner['update-clients']['success'] = true
            } else {
              this.showLoadingSpinner['update-clients']['error'] = true
            }
            this.showLoadingSpinner['update-clients']['loader'] = false
            setTimeout(() => this.showLoadingSpinner['update-clients'] = {}, 4000)
        }.bind(this))
    },
    updateTaxesRecords() {
      this.showLoadingSpinner['taxes']['loader'] = true

      api({ endpoint: `${TAXES_ENDPOINT}`, method: "POST", addTokenToHeaders: true })
        .then(function (res) {
            const responseStatus = res.data.status
            if (responseStatus === 'success') {
              this.showLoadingSpinner['taxes']['success'] = true
            } else {
              this.showLoadingSpinner['taxes']['error'] = true
            }
            this.showLoadingSpinner['taxes']['loader'] = false
            setTimeout(() => this.showLoadingSpinner['taxes'] = {}, 4000)
        }.bind(this))
    }
  }
}
</script>