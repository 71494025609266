<template>
	<div class="row m-auto">
		<div class="col-sm-2 mb-4">
			<div class="dashboard-loader">Loading...</div>
		</div>
	</div>
</template>

<script>
  export default {}
</script>