<template>
  <li class="nav-item">
    <a class="nav-link api-link"
       href="#"
       @click.prevent="linkClicked()"
    >
      <i :class="'fa ' + icon" aria-hidden="true"></i>
      <div class="mr-3 link-text">
        <slot></slot>
      </div>
      <div class="loader-wrapper">
        <div class="loader" v-if="showLoadingSpinner['loader']">Loading...</div>
        <i class="fa fa-check" v-if="showLoadingSpinner['success']"></i>
        <i class="fa fa-times" v-if="showLoadingSpinner['error']"></i>
      </div>
      <div class="small" v-if="caption">{{ caption }}</div>
    </a>
  </li>
</template>
<script>
export default {
    props: {
        icon: {
            type: String,
            required: true
        },
        caption: {
            type: String
        },
        showLoadingSpinner: {
            type: Object
        }
    },
    methods: {
      linkClicked() {
        this.$emit('linkClicked');
      }
    }
}
</script>