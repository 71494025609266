<template>
	<button type="button" :class="'btn btn-' + type" :disabled="disabled">
		<i :class="'fa ' + icon" v-if="!spin && !showCheckIconAfterActiomCompleted"></i>
		<i class="fa fa-check" v-if="showCheckIconAfterActiomCompleted"></i>
		<div class="button-loader-wrapper" v-if="spin">
			<div class="loader">Loading...</div>
		</div>
		<span>{{ text }}</span>
	</button>
</template>

<script>
	export default {
		props: {
			disabled: { type: Boolean },
			icon: { type: String, default: 'fa-save' },
			text: { type: String },
			type: { type: String, default: 'success' },
			spin: { type: Boolean }
		},
		data: () => ({
			showCheckIconAfterActiomCompleted: false
		}),
		watch: {
			spin: 'updateSpinnerIcon'
		},
		methods: {
			updateSpinnerIcon() {
				this.showCheckIconAfterActiomCompleted = false
				if (!this.spin) {
					this.showCheckIconAfterActiomCompleted = true
					setTimeout(() => this.showCheckIconAfterActiomCompleted = false, 1500)
				}
			}
		}
  }
</script>