import AddDevices from "../pages/AddDevices";
import ConfirmInvoices from "../pages/ConfirmInvoices";
import Devices from "../pages/Devices";
import Home from "../pages/Home";
import Invoices from "../pages/Invoices";
import Login from "../pages/Login";
import Payments from "../pages/Payments";
import Sales from "../pages/Sales";
import SendInvoices from "../pages/SendInvoices";
import Error_404 from "../pages/Error_404";

export default [
    { path: '/login', component: Login, name: 'login' },
    {
        path: '/',
        component: Home,
        children: [
            { path: '/', redirect: '/invoices', name: 'dashboard' },
            { path: '/add-devices', component: AddDevices, name: 'add-devices', props: true },
            { path: '/confirm-invoices', component: ConfirmInvoices, name: 'confirm-invoices', props: true },
            { path: '/devices', component: Devices },
            { path: '/invoices', component: Invoices, name: 'invoices' },
            { path: '/payments', component: Payments },
            { path: '/sales', component: Sales },
            { path: '/send-invoices', component: SendInvoices, name: 'send-invoices', props: true },
            { path: "/:catchAll(.*)", component: Error_404 }
        ]
    }
]