<template>

  <div class="row mb-3">
		<div class="col-sm-12">

        <action-button text="Update"
                       :spin="spinners['update-' + template.id]"
                       :disabled="spinners['update-' + template.id]"
                       v-on:click="updateTemplate()"
        ></action-button>

		</div>
	</div>
	<error v-if="templateUpdateError">
		{{ templateUpdateErrorMessage }}
	</error>
  <table class="template-table">
		<tr>
			<td class="template-col template-heading">
				Field
			</td>
			<td class="template-col template-heading">
				Excel column
			</td>
		</tr>
		<tr v-for="(columnValue, columnKey) in mapping" :key="columnKey">
			<td class="template-col">
				{{ columnKey }}
				<span class="template-required" v-if="columnIsRequired(columnKey)">
					(required)
				</span>
			</td>
			<td class="template-col">

				<div class="navbar-collapse letters-table">
					<ul class="nav navbar-nav">
						<li class="dropdown">
							<a class="dropdown-toggle letter-dropdown" data-toggle="dropdown">
								{{ columnValue }} <b class="caret"></b>
							</a>
							<ul class="dropdown-menu multi-column columns-3">
								<li class="row">
									<div class="col-sm-2" v-for="i in [0,1,2,3,4,5]" :key="i">

										<ul class="multi-column-dropdown">
											<li v-if="i === 0"
													v-on:click="mapThisColumnToInvoiceField(columnKey, columnValue, 'N/A')"
											>
												<span>N/A</span>
											</li>
											<li v-if="i > 0" class="content-li"></li>
											<li class="divider"></li>
											<li v-for="excelColumn in excelColumns[i]"
													:key="excelColumn"
													v-on:click="mapThisColumnToInvoiceField(columnKey, columnValue, excelColumn)"
											>
												<span>{{ excelColumn }}</span>
											</li>
										</ul>

									</div>
								</li>
							</ul>

						</li>
					</ul>
				</div>

			</td>
		</tr>
	</table>

</template>

<script>
  import api from "../../services/api";
	const TEMPLATES_ENDPOINT = 'templates';

	export default {
		props: {
			template: {}
		},
		data: () => ({
			mapping: {},
			spinners: {},
			excelColumns: [
				[
					'A', 'B', 'C', 'D', 'E', 'F', 'G',
					'H', 'I', 'J', 'K', 'L', 'M'
				],
				[
					'N', 'O', 'P', 'Q', 'R', 'S', 'T',
					'U', 'V', 'W', 'X', 'Y', 'Z'
				],
				[
					'AA', 'AB', 'AC', 'AD', 'AE', 'AF', 'AG',
					'AH', 'AI', 'AJ', 'AK', 'AL', 'AM'
				],
				[
					'AN', 'AO', 'AP', 'AQ', 'AR', 'AS', 'AT',
					'AU', 'AV', 'AW', 'AX', 'AY', 'AZ'
				],
				[
					'BA', 'BB', 'BC', 'BD', 'BE', 'BF', 'BG',
					'BH', 'BI', 'BJ', 'BK', 'BL', 'BM'
				],
				[
					'BN', 'BO', 'BP', 'BQ', 'BR', 'BS', 'BT',
					'BU', 'BV', 'BW', 'BX', 'BY', 'BZ'
				]
			],
			templateUpdateError: false,
			templateUpdateErrorMessage: ''
		}),
		created: function() {
			this.mapping = JSON.parse(this.template.mapping)
		},
		methods: {
			updateTemplate() {
        this.spinners['update-' + this.template.id] = true
				this.templateUpdateError = false
				this.templateUpdateErrorMessage = ''

				const templateData = {
					id: this.template.id,
					title: this.template.title,
					mapping: JSON.stringify(this.mapping)
				}
				const self = this
        api({
            endpoint: `${TEMPLATES_ENDPOINT}/${this.template.id}`,
            method: 'PUT',
            data: templateData,
            addTokenToHeaders: true
        })
					.then(function (res) {
						this.spinners['update-' + this.template.id] = false

						if (!res.data || res.data.status !== 'success') {
              this.templateUpdateError = true
              this.templateUpdateErrorMessage = res.data.message
						}
					}.bind(this),
          function(error) {
						self.spinners['update-' + this.template.id] = false
            self.templateUpdateError = true
						self.templateUpdateErrorMessage = 'Error: ' + error
          })

			},
			columnIsRequired(columnKey) {
				const requiredColumns = [
					'Customer',
					'Make',
					'Model',
					'Serial #',
					'State/Province',
					'Mono-Beg',
					'Mono-End',
					'Color-Beg',
					'Color-End',
					'Device Total',
					'Mono-CPC',
					'Color-CPC',
					'Monocolor-CPC',
					'Color2-CPC',
					'Color3-CPC'
				]
				return requiredColumns.includes(columnKey)
			},
			mapThisColumnToInvoiceField(columnKey, columnValue, excelColumn) {
				if (columnValue !== excelColumn) {
					let columnExists = false
					for (const field in this.mapping) {
						if (excelColumn !== 'N/A' && this.mapping[field] === excelColumn) {
							columnExists = true
						}
					}
					if (!columnExists) {
						this.mapping[columnKey] = excelColumn
					}
				}
			}
		}
  }
</script>