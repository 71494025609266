<template>

  <h2-title :title="'Confirm Invoices'"></h2-title>

  <div class="alert alert-danger"
       v-if="possibleIncorrectCpcValues"
  >
    There are possible errors in cost per copy for these devices:
    <div v-for="(errors, deviceSerialNumber) in incorrectCpcData" :key="deviceSerialNumber">
      <span v-for="errorType in errors" :key="errorType">
        <br><b>S/N: {{ deviceSerialNumber }}</b>: {{ capitalize(errorType) }} CPC is greater than {{ errorType }} price
      </span>
    </div>
    <br>Page prices can be corrected in the <router-link :to="'/devices'">devices section</router-link>
  </div>

  <div class="card"
       v-for="(clientData, zohoClientId) in devicesGroupedByClient"
       :key="zohoClientId"
  >
     <div class="card-header bg-info">
      <b>{{ clientData.info.customer_title }}</b>
    </div>
    <div class="card-body">

      <div class="row">
        <div class="col-sm-12">

          <div class="row">
            <div class="col-sm-4">
              <div class="pb-3 confirm-invoices-cell">
                Client Routing No.:
                <br>
                <input
                  class="form-control"
                  :value="clientData['info']['routing_no']"
                  disabled
                />
                <input
                  type="hidden"
                  :value="clientData['info']['routing_no']"
                />
              </div>
              <div class="pb-3 confirm-invoices-cell">
                Client Bank Account:
                <br>
                <input
                  class="form-control"
                  :value="clientData['info']['bank_account']"
                  disabled
                />
                <input
                  class="form-control"
                  type="hidden"
                  :value="clientData['info']['bank_account']"
                />
              </div>
              <div class="pb-3 confirm-invoices-cell">
                Client Credit Card Number:
                <br>
                <input
                  class="form-control"
                  :value="clientData['info']['credit_card_no']"
                  disabled
                />
                <input
                  class="form-control"
                  type="hidden"
                  value="clientData['info']['credit_card_no']"
                />
              </div>
            </div>
            <div class="col-sm-4">
              <div class="pb-3 confirm-invoices-cell">
                Client Credit Card Date of Expiration:
                <br>
                <input
                  class="form-control"
                  :value="clientData['info']['date_of_expiration']"
                  disabled
                />
                <input
                  class="form-control"
                  type="hidden"
                  :value="clientData['info']['date_of_expiration']"
                />
              </div>
              <div class="pb-3 confirm-invoices-cell">
                Create ACH file:
                <br>
                <select class="form-control"
                        v-model="clientData['info']['add_ach']"
                        disabled
                >
                  <option :value="true">
                    Yes
                  </option>
                  <option :value="false">
                    No
                  </option>
                </select>
              </div>
              <div class="pb-3 confirm-invoices-cell">
                Create Authorize.net file:
                <br>
                <select class="form-control"
                        v-model="clientData['info']['add_authorize_net']"
                        disabled
                >
                  <option :value="true">
                    Yes
                  </option>
                  <option :value="false">
                    No
                  </option>
                </select>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="p-0 confirm-invoices-cell">
                Due date for invoice:
                <br>
                <select class="form-control"
                        v-model="clientData['info']['invoice_due_date']"
                >
                  <option value="15">
                    Net 15
                  </option>
                  <option value="30">
                    Net 30
                  </option>
                  <option value="45">
                    Net 45
                  </option>
                  <option value="60">
                    Net 60
                  </option>
                  <option value="on_receipt">
                    Due on receipt
                  </option>
                </select>
              </div>
              <div class="p-0 confirm-invoices-cell">
                Send the invoice to the first contact in contacts list:
                <br>
                <input type="checkbox"
                       v-model="clientData['info']['select_first_contact']"
                />
              </div>
              <div class="pb-3 confirm-invoices-cell">
                Check all payments options:
                <br>
                <input type="checkbox"
                       v-model="clientData['info']['select_all_payment_options']"
                />
              </div>
            </div>
          </div>

        </div>

        <div class="col-sm-12">
          <div class="card"
               v-for="deviceData in clientData['devices']"
               :key="deviceData['id']"
          >
            <div class="card-header bg-warning">
              <b>{{ deviceData['maker'] }} {{ deviceData['model'] }}, S/N: {{ deviceData['serial_number'] }}</b>
            </div>
            <div class="card-body">

              <div class="row">
                <div class="col-sm-6">
                  <div class="row">
                    <div class="col-sm-12 pb-3">
                      Contract ID:
                      <br>
                      <input class="form-control"
                             :value="deviceData['contract_id']"
                             disabled
                      />
                    </div>
                    <div class="col-sm-12">
                      City friendly name:
                      <br>
                      <input class="form-control"
                             v-model="deviceData['city']"
                       />
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="row">
                    <div class="col-sm-12 pb-3">

                      <div class="col-sm-12 p-0">
                        <b>Base price:</b>
                      </div>

                      <div class="col-sm-4 p-0">
                        <input type="text"
                               class="form-control text-center"
                               :value="deviceData['base_price']"
                               disabled
                        />
                      </div>

                    </div>

                    <div class="col-sm-12 pb-3"
                         v-for="type in ['mono', 'color', 'monocolor', 'color2', 'color3']"
                         :key="type"
                    >
                      <div class="row">
                        <div class="col-sm-12">
                          <b>{{ capitalize(type) }} pages: </b>
                        </div>
                        <div class="col-sm-2 confirm-invoices-included-cell">
                          Included:
                          <input class="form-control text-center"
                                 v-model="deviceData[type + '_included']"
                                 disabled
                          />
                        </div>
                        <div class="col-sm-2">
                          Usage:
                          <input class="form-control text-center"
                                 v-model="deviceData[type + '_usage']"
                                 v-bind:class="{
                                  warning_cell:
                                  deviceData[type + '_usage'] < deviceData[type + '_included']
                                  }"
                          />
                        </div>
                        <div class="col-sm-1 text-center confirm-invoices-high-cell">
                          <b>X</b>
                        </div>
                        <div class="col-sm-3">
                          Cost:
                          <input class="form-control text-center"
                                 v-model="deviceData[type + '_price']"
                                 v-bind:class="{
                                    warning_cell:
                                    (deviceData[type + '_usage'] > deviceData[type + '_included']
                                      &&
                                    deviceData[type + '_price'] == 0)
                                    ||
                                    deviceData[type + '_cost_error']
                                  }"
                                 disabled
                          />
                        </div>
                        <div class="col-sm-1 text-center confirm-invoices-high-cell">
                          <b>=</b>
                        </div>
                        <div class="col-sm-3">
                          Total:
                          <input class="form-control text-center"
                                 :value="getTotalFromUsageAndIncludedValues(deviceData, type)"
                                 disabled
                          />
                        </div>
                        <div class="col-sm-12"
                             v-if="deviceData[type + '_cost_error']"
                        >
                          <div class="alert alert-danger">
                            Possible error in price per copy charged to the customer!
                            <br>Page prices can be corrected in the <router-link :to="'/devices'">devices section</router-link>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-12 pb-0"
                         v-if="deviceData['other_charges_desc'] !== '' && deviceData['other_charges_amount'] > 0"
                    >

                      <div class="col-sm-12 p-0">
                        <b>Other charges:</b>
                      </div>

                      <div class="col-sm-12 p-0">
                        {{ deviceData['other_charges_desc'] }}: ${{ deviceData['other_charges_amount'] }}
                      </div>

                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>

        </div>
      </div>


    </div>

  </div>

  <div class="col-sm-12 p-0 pt-3 pb-3">

    <action-button icon="fa-upload"
                   text="Send invoices"
                   :spin="spinners['send-invoices']"
                   :disabled="spinners['send-invoices']"
                   v-on:click="sendInvoices()"
    ></action-button>

  </div>

</template>

<script>

export default {
    components: {},
    props: ['encodedDevices'],
    data: () => ({
      possibleIncorrectCpcValues: false,
      devicesGroupedByClient: {},
      incorrectCpcData: {},
      spinners: {}
    }),
    created: function() {
        if (this.encodedDevices) {
          this.devicesGroupedByClient = JSON.parse(this.encodedDevices)

          const types = ['mono', 'color', 'monocolor', 'color2', 'color3']
          Object.keys(this.devicesGroupedByClient).forEach(clientId => {
            const devices = this.devicesGroupedByClient[clientId]['devices']

            devices.forEach(device => {
              types.forEach(type => {
                if (device[type + '_cost_error']) {
                  if (!this.incorrectCpcData[device.serial_number]) {
                    this.incorrectCpcData[device.serial_number] = []
                  }

                  if (!this.possibleIncorrectCpcValues) {
                    this.possibleIncorrectCpcValues = true
                  }
                  this.incorrectCpcData[device.serial_number].push(type)
                }
              })
            })
          })
        } else {
          this.$router.replace({name:'invoices'})
        }
    },
    methods: {
      capitalize(s) {
        return s.charAt(0).toUpperCase() + s.slice(1)
      },
      sendInvoices() {
        this.spinners['send-invoices'] = true
        const devicesGroupedByClient = JSON.stringify(this.devicesGroupedByClient)
        this.$router.replace({name:'send-invoices', params:{devicesGroupedByClient}})
      },
      getTotalFromUsageAndIncludedValues(deviceData, type) {
        if (deviceData[type + '_usage'] > deviceData[type + '_included']) {
          return Math.ceil(
          (
              (deviceData[type + '_usage'] - deviceData[type + '_included']) * deviceData[type + '_price']
            )*10000)/10000;
        } else {
          return 0;
        }
      }
    }
}

</script>