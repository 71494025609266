/*
  A simple wrapper around the native
  `fetch()` function.
*/
export async function quickFetch(endpoint, options = {}) {
  const response = await fetch(endpoint, options);
  return await response.json();
}
export async function quickFetchText(endpoint, options = {}) {
  const response = await fetch(endpoint, options);
  return await response.status === 200 ? response.text() : '';
}

/*
  Better than importing moment which can be slow
*/
export function formatDate(value) {
  const createdAt = new Date(value)

  return ("0" + (createdAt.getUTCMonth()+1)).slice(-2) + "." +
          ("0" + createdAt.getUTCDate()).slice(-2) + "." +
          createdAt.getUTCFullYear()
}
export function formatDateTime(value) {
  const createdAt = new Date(value)

  return createdAt.toLocaleString()
}

export function formatValue(value) {
  value = value ? value : 0
  const val = (value / 1).toFixed(2)
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export function toLowerCase(s) {
  return s ? s.toLowerCase() : null
}

export function getExpirationDateFromToken(token) {
    if (!token)  return null

    const tokenParts = token.split('.')

    if (!tokenParts[1])  return null

    const base64Url = tokenParts[1];

    const base64 = base64Url.replace('-', '+').replace('_', '/')

    if (!base64)  return null

    const decodedBase64 = window.atob(base64)

    if (!decodedBase64)  return null

    return JSON.parse(decodedBase64)['exp']
}

export function paginateRecords(array, recordsPerPage) {
    return array.reduce((acc, val, i) => {
      let idx = Math.floor(i / recordsPerPage)
      let page = acc[idx] || (acc[idx] = [])
      page.push(val)

      return acc
    }, [])
}