import Vue from 'vue';
import {quickFetch, quickFetchText} from './helpers';
import router from "../routes/router";

const baseDomain = process.env.VUE_APP_BASE_DOMAIN
const zohobooksTokenName = process.env.VUE_APP_ZOHOBOOKS_TOKEN_NAME

async function withPolling(callback, interval) {
  const data = await callback();

  // If no polling interval is given we
  // return a regular object with the data.
  if (!interval) return { data };

  // Otherwise, we create a new `Vue.observable()`
  // instance and refetch the data according to
  // the specified polling interval.
  const observableData = Vue.observable({ data });
  const poll = () => {
    setTimeout(async () => {
      observableData.data = { ...(await callback()) };
      poll();
    }, interval);
  };
  poll();

  return observableData;
}

export default function api({ endpoint, method = 'GET', data = {}, addTokenToHeaders = true, interval }) {

  const options = {
    method: method,
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    }
  }
  if (method === 'POST' || method === 'PUT' || method === 'DELETE') {
    options['body'] = JSON.stringify(data)
  }
  if (addTokenToHeaders) {
    options['headers']['Authorization'] = router.app.$cookies.get(zohobooksTokenName)
  }
  return withPolling(() => quickFetch(baseDomain + endpoint, options), interval);
}

export function fileApi({ endpoint, method = 'GET'}) {

  const options = {
    method: method,
    headers: {
      'Content-Type': 'text/html'
    }
  }
  return withPolling(() => quickFetchText(baseDomain + endpoint, options));
}

export function invoicesApi({ endpoint, method = 'GET', data = {}, addTokenToHeaders = true, interval }) {

  const options = {
    method: method,
    credentials: 'include',
    body: data,
    headers: {}
  }
  if (addTokenToHeaders) {
    options['headers']['Authorization'] = router.app.$cookies.get(zohobooksTokenName)
  }
  return withPolling(() => quickFetch(baseDomain + endpoint, options), interval);
}