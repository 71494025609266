<template>
  <br>
  <span class="sales-rep-name">
    {{ salesRep.firstname }} {{ salesRep.lastname }}:
  </span>
  <br><i>Current month</i>:
  <br>${{ formatValue(currentMonthTotalPrice - currentMonthTotalCost) }}
  / Average:
  {{ currentMonthTotalCost > 0 ?
  formatValue(((currentMonthTotalPrice - currentMonthTotalCost)/currentMonthTotalCost)*100) : 0 }}%
  <br><i>Previous month</i>:
  <br>${{ formatValue(previousMonthTotalPrice - previousMonthTotalCost) }}
  / Average:
  {{ previousMonthTotalCost > 0 ?
  formatValue(((previousMonthTotalPrice - previousMonthTotalCost)/previousMonthTotalPrice)*100) : 0 }}%
  <br><i>All time</i>:
  <br>${{ formatValue(totalPrice - totalCost) }}
  / Average:
  {{ totalCost > 0 ? formatValue((totalPrice - totalCost)/totalCost*100) : 0 }}%
  <br><br>
</template>

<script>
import {formatValue} from '../services/helpers'
export default {
  props: {
    salesRep: {
        type: Object
    },
    sales: {
        type: Array
    },
    currentMonthTotalPrice: { type: Number, default: 0 },
    currentMonthTotalCost:  { type: Number, default: 0 },
    previousMonthTotalPrice:  { type: Number, default: 0 },
    previousMonthTotalCost:  { type: Number, default: 0 },
    totalPrice:  { type: Number, default: 0 },
    totalCost:  { type: Number, default: 0 }
  },
  data: () => ({
  }),
  methods: {
      formatValue: formatValue
  }
}
</script>