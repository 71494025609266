<template>

  <main class="text-center form-signin">

    <error v-if="loginError">
      {{ errorMessage }}
    </error>

    <div class="row">
      <div class="col mb-3">

        <form class="m-auto"
              v-if="showLoginForm && csrfToken.length > 0"
              v-on:submit.prevent="login"
        >
          <span v-html="iconSvg"></span>
          <h1 class="h3 mb-3 font-weight-normal">
            Please sign in
          </h1>

          <label for="inputEmail" class="sr-only">Email address</label>
          <input type="email" ref="email" id="inputEmail" class="form-control" placeholder="Email address" required autofocus>
          <label for="inputPassword" class="sr-only">Password</label>
          <input type="password" ref="password" id="inputPassword" class="form-control" placeholder="Password" required>

          <input type="hidden" ref="_token" :value="csrfToken">

          <button class="btn btn-lg btn-primary btn-block login-button" type="submit">
            <div class="button-loader-wrapper" v-if="loginSpin">
              <div class="loader">Loading...</div>
            </div>
            Sign in
          </button>
        </form>

        <dashboard-spinner v-if="!showLoginForm || csrfToken.length === 0"></dashboard-spinner>

      </div>
    </div>

  </main>

</template>

<script>
import feather from "feather-icons";
import '../assets/signin.css';
import api from "../services/api";

const zohobooksTokenName = process.env.VUE_APP_ZOHOBOOKS_TOKEN_NAME
const zohobooksApiLoginUrl = process.env.VUE_APP_ZOHOBOOKS_API_LOGIN_URL
const zohobooksApiScope = process.env.VUE_APP_ZOHOBOOKS_API_SCOPE
const zohobooksClientId = process.env.VUE_APP_ZOHOBOOKS_CLIENT_ID
const zohobooksRedirectUri = process.env.VUE_APP_ZOHOBOOKS_REDIRECT_URL

const CSRF_ENDPOINT = 'get_csrf'
const LOGIN_ENDPOINT = 'login'
const REQUEST_TOKEN_ENDPOINT = 'request_token'

export default {
  data: () => ({
    csrfToken: '',
    output: {},
    showLoginForm: false,
    loginSpin: false,
    loginError: false,
    errorMessage: ''
  }),
  computed: {
    iconSvg: function () {
      return feather.toSvg('key')
    }
  },
  mounted: function() {
    const code = this.$route.query.code

    if (code) {
      const params = {
        code: code
      }

      api({ endpoint: `${REQUEST_TOKEN_ENDPOINT}`, method: 'POST', data: params, addTokenToHeaders: false })
        .then(function (res) {
          const JWTToken = res.data.token
          if (JWTToken) {
            this.$cookies.set(zohobooksTokenName, JWTToken)
            this.$router.replace({name:'dashboard'})
          } else {
            this.$router.replace({name:'login'})
          }

        }.bind(this))
    } else {
      this.showLoginForm = true

      api({ endpoint: `${CSRF_ENDPOINT}`, addTokenToHeaders: false })
        .then(function (res) {
          this.csrfToken = res.data
        }.bind(this))
    }
  },
  methods: {
    login() {
      this.loginSpin = true
      this.loginError = false
      this.errorMessage = ''

      const params = {
        'email': this.$refs.email.value,
        'password': this.$refs.password.value,
        '_token': this.$refs._token.value
      }

      const self = this
      api({ endpoint: `${LOGIN_ENDPOINT}`, method: 'POST', data: params, addTokenToHeaders: false })
        .then(function (res) {
          // TODO: move env variable in config file

          if (res.data && res.data.status && res.data.status === 'success') {
            const params = {
              'scope': zohobooksApiScope,
              'client_id': zohobooksClientId,
              'response_type': 'code',
              'redirect_uri': zohobooksRedirectUri
            }
            const encodedParams = Object.entries(params).map(kv => kv.map(encodeURIComponent).join("=")).join("&");
            const url = zohobooksApiLoginUrl + encodedParams

            window.location.replace(url)
          } else {
              this.loginSpin = false
              this.loginError = true
              this.errorMessage = res.data.message
          }
        }.bind(this),
        function(error) {
          self.loginSpin = false
          self.loginError = true
          self.errorMessage = 'Error: ' + error
        })
    }
  }
}
</script>