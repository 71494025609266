<template>
  <tr>
    <td v-if="device.id">{{ device.id }}</td>
    <td>{{ device.maker }} {{ device.model }}</td>
    <td>{{ device.serial_number }}</td>
    <td v-if="device.created_at">{{ formatDate(device.created_at) }}</td>
  </tr>
  <tr>
  <td class="white-bg" colspan="4">

    <div class="accordion device-accordion"
         :id="'deviceAccordion_' + uniqueIndex"
    >
      <div class="card">
        <div class="card-header custom-accordion"
             :id="'heading_' + uniqueIndex"
        >
          <h2 class="mb-0">
            <button class="btn btn-link btn-block text-left collapsed"
                    type="button"
                    data-toggle="collapse"
                    :data-target="'#collapse_' + uniqueIndex"
                    aria-expanded="false"
                    :aria-controls="'collapse_' +  uniqueIndex"
            >
              Settings
            </button>
          </h2>
        </div>
        <div :id="'collapse_' + uniqueIndex"
             class="collapse"
             :aria-labelledby="'heading_' + uniqueIndex"
             :data-parent="'#deviceAccordion_' + uniqueIndex"
        >
          <div class="card-body">

            <error v-if="deleteDeviceError">
              {{ deleteDeviceErrorMessage }}
            </error>

            <div class="row mb-3">
              <div class="col-md-3 offset-md-9">

                <div class="row">
                  <div class="col text-right">
                    <action-button text="Save"
                                   :spin="spinners['update-' + uniqueIndex]"
                                   :disabled="spinners['update-' + uniqueIndex]"
                                   v-on:click="saveOrUpdateDevice(uniqueIndex)"
                    ></action-button>
                  </div>
                  <div class="col text-right"
                       v-if="showDeleteButton"
                  >
                    <action-button type="danger"
                                   icon="fa-trash"
                                   text="Delete"
                                   :spin="spinners['delete-' + uniqueIndex]"
                                   :disabled="spinners['delete-' + uniqueIndex]"
                                   v-on:click="deleteDevice(uniqueIndex)"
                    ></action-button>
                  </div>
                </div>

              </div>
            </div>
            <div class="row">
              <div class="col-sm">
                <table class="table mb-3">
                  <thead>
                    <tr class="d-flex">
                      <th class="col-6">Sales Rep</th>
                      <th class="col-6">Client Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="d-flex">
                      <td class="col-6">

                        <select class="form-control"
                                v-model="deviceData.sales_rep"
                                v-bind:class="{'is-invalid': hasErrors['sales_rep']}"
                        >
                          <option v-for="salesRep in salesReps"
                                  v-bind:value="toLowerCase(salesRep.firstname)"
                                  :key="salesRep.id"
                          >
                            {{ salesRep.firstname }} {{ salesRep.lastname }}
                          </option>
                        </select>
                        <small class="text-danger">
                          {{ hasErrors['sales_rep'] }}
                        </small>
                      </td>
                      <td class="col-6">
                        <select class="form-control"
                                v-model="deviceData.client_id"
                                v-bind:class="{'is-invalid': hasErrors['client_id']}"
                        >
                          <option v-for="client in clients"
                                  v-bind:value="client.id"
                                  :key="client.id"
                          >
                            {{ client.contact_name }}
                          </option>
                        </select>
                        <small class="text-danger">
                          {{ hasErrors['client_id'] }}
                        </small>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table class="table mb-3">
                  <thead>
                    <tr class="d-flex">
                      <th class="col-6">Friendly Name</th>
                      <th class="col-6">Tax</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="d-flex">
                      <td class="col-6">
                        <input type="text"
                               class="form-control"
                               v-model="deviceData.friendly_name"
                        />
                      </td>
                      <td class="col-6">
                        <select class="form-control"
                                v-model="deviceData.tax"
                        >
                          <option v-for="tax in taxes"
                                  v-bind:value="tax.state_code"
                                  :key="tax.id"
                          >
                            {{ tax.name }}
                          </option>
                        </select>
                      </td>
                    </tr>
                  </tbody>
                </table>

              </div>
              <div class="col-sm">

                <table class="table mb-3">
                  <thead>
                    <tr class="d-flex">
                      <th class="col-6">Add red flag note</th>
                      <th class="col-6">Red Flag Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="d-flex">
                      <td class="col-6">
                        <div class="form-check">
                          <input :id="'redFlag_' + uniqueIndex"
                                 class="form-check-input"
                                 type="checkbox"
                                 v-model="deviceData.add_redflag"
                          />
                          <label class="form-check-label"
                                 :for="'redFlag_' + uniqueIndex"
                          >
                            Add red flag note
                          </label>
                        </div>
                      </td>
                      <td class="col-6">
                        <input type="text"
                               class="form-control"
                               v-model="deviceData.redflag_value"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>

              </div>
            </div>
            <div class="row">
              <div class="col-sm">
                <table class="table mb-0">
                  <thead>
                    <tr>
                      <th scope="col">Param</th>
                      <th scope="col">Price</th>
                      <th scope="col">Incl. prints</th>
                    </tr>
                  </thead>
                <tbody>
                  <tr v-for="priceField in priceFields" :key="priceField.title">
                    <td>{{ priceField.verbose_title }}</td>
                    <td>
                      <input class="form-control"
                             v-model="deviceData[priceField.title + '_price']"
                             type="number"
                             step="0.01"
                      />
                    </td>
                    <td>
                      <input v-if="priceField.hasPrints"
                             v-model="deviceData[priceField.title + '_included']"
                             class="form-control"
                             type="number"
                      />
                    </td>
                  </tr>
                </tbody>
                </table>
              </div>
              <div class="col-sm">
                <table class="table mb-0">
                  <thead>
                    <tr>
                      <th scope="col" colspan="2">Cost escalations</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div class="form-check">
                          <input class="form-check-input"
                                 :id="'fixedRadio_' + uniqueIndex"
                                 type="radio"
                                 v-model="deviceData.escalation_type"
                                 value="fixed"
                          />
                          <label class="form-check-label" :for="'fixedRadio_' + uniqueIndex">
                            Fixed?
                          </label>
                        </div>
                      </td>
                      <td>
                        <div class="form-inline" v-if="deviceData.escalation_type === 'fixed'">
                          <input class="form-control mr-3"
                                 type="text"
                                 v-model="deviceData.escalation_value"
                          />
                          <span>%</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="form-check">
                          <input class="form-check-input"
                                 :id="'rulesRadio_' + uniqueIndex"
                                 type="radio"
                                 v-model="deviceData.escalation_type"
                                 value="rules_based"
                          />
                          <label class="form-check-label" :for="'rulesRadio_' + uniqueIndex">
                            Rules Based?
                          </label>
                        </div>
                      </td>
                      <td>
                        <div class="form-check" v-if="deviceData.escalation_type === 'rules_based'">
                          <input class="form-check-input"
                                 type="radio"
                                 :id="'currentFixedRules_' + uniqueIndex"
                                 value="current"
                                 v-model="deviceData.escalation_value"
                          />
                          <label class="form-check-label" :for="'currentFixedRules_' + uniqueIndex">
                            Current ( +3% if average bill is &#60; $100/Month; +0% if &#62; $100/Month )
                          </label>
                        </div>
                        <div class="form-check" v-if="deviceData.escalation_type === 'rules_based'">
                          <input class="form-check-input"
                                 type="radio"
                                 :id="'notCurrentFixedRules_' + uniqueIndex"
                                 value="not_current"
                                 v-model="deviceData.escalation_value"
                          />
                          <label class="form-check-label" :for="'notCurrentFixedRules_' + uniqueIndex">
                            Not Current ( +6% if average bill is &#60; $100/Month; +3% if &#62; $100/Month )
                          </label>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <b>Start Date:</b>
                      </td>
                      <td>
                        <div class="row">
                          <div class="col-sm-6">
                            <datepicker class="form-control remove-readonly"
                                        v-model="escalationStart"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>

                  </tbody>
                </table>

              </div>
            </div>

          </div>
        </div>
      </div>
    </div>


    </td>
  </tr>
</template>

<script>
  import Datepicker from 'vue3-datepicker'
  import {formatDate, toLowerCase} from "../../services/helpers";
  import api from "../../services/api";

  const DEVICES_ENDPOINT = 'devices'

  export default {
    components: {
      Datepicker
    },
    props: {
      device: {
          type: Object
      },
      clients: {
          type: Array
      },
      salesReps: {
          type: Array
      },
      taxes: {
          type: Array
      },
      showDeleteButton: {
          type: Boolean
      },
      uniqueIndex: {
          type: Number
      }
    },
    data: () => ({
      toggled: false,
      deviceData: {
        client_id: '',
        sales_rep: '',
        friendly_name: '',
        tax: '',
        add_redflag: false,
        redflag_value: '',
        escalation_type: '',
        escalation_value: '',
        escalation_start: ''
      },
      escalationStart: '',
      hasErrors: {},
      spinners: {},
      priceFields: [
          {title: 'base', verbose_title: 'Base', hasPrints: false},
          {title: 'mono', verbose_title: 'Mono cost', hasPrints: true},
          {title: 'color', verbose_title: 'Color cost', hasPrints: true},
          {title: 'monocolor', verbose_title: 'Mono/color cost', hasPrints: true},
          {title: 'color2', verbose_title: 'Color2 cost', hasPrints: true},
          {title: 'color3', verbose_title: 'Color3 cost', hasPrints: true}
      ],
      deleteDeviceError: false,
      deleteDeviceErrorMessage: ''
    }),
    updated: function() {
      if (this.toggled) {
          this.init()
          this.toggled = false
      }
    },
    created: function() {
      this.init()
    },
    methods: {
      init() {
        this.deviceData.client_id = this.device.client_id ? this.device.client_id : null
        this.deviceData.sales_rep = this.device.sales_rep ? this.device.sales_rep.toLowerCase() : null
        this.deviceData.maker = this.device.maker
        this.deviceData.model = this.device.model
        this.deviceData.serial_number = this.device.serial_number
        this.deviceData.friendly_name = this.device.friendly_name
        this.deviceData.add_redflag = this.device.add_redflag
        this.deviceData.redflag_value = this.device.redflag_value
        this.deviceData.escalation_type = this.device.escalation_type ? this.device.escalation_type :  'fixed'
        this.deviceData.escalation_value = this.device.escalation_value ? this.device.escalation_value :  '0'
        this.deviceData.escalation_start = this.device.escalation_start ? this.device.escalation_start :  null
        if (!isNaN(this.device.price_total)) {
          this.deviceData.price_total = this.device.price_total
        }
        this.deviceData.city = this.device.city
        if (this.device.escalation_start) {
          this.escalationStart = new Date(this.device.escalation_start)
        }

        this.deviceData.tax = ''

        this.priceFields.forEach(field => {
          this.deviceData[field.title + '_price'] = Number(this.device[field.title + '_price'])
          if (field.hasPrints) {
            this.deviceData[field.title + '_included'] = Number(this.device[field.title + '_included'])
          }
        })
      },
      formatDate: formatDate,
      toLowerCase: toLowerCase,
      saveOrUpdateDevice(uniqueIndex) {
        this.spinners['update-' + uniqueIndex] = true
        this.hasErrors = {}

        if (this.escalationStart) {
          var escalationDate = new Date(this.escalationStart)
          // TODO: properly fix the bug with timezone offset
          escalationDate.setHours(escalationDate.getHours() + 3)
          this.deviceData.escalation_start = escalationDate.toISOString().split('T')[0]
        }

        this.priceFields.forEach(field => {
          this.deviceData[field.title + '_price'] = Number(this.deviceData[field.title + '_price'])
          if (field.hasPrints) {
            this.deviceData[field.title + '_included'] = Number(this.deviceData[field.title + '_included'])
          }
        })
        if (typeof this.deviceData['price_total'] != 'undefined') {
          this.deviceData['price_total'] = Number(this.deviceData['price_total'])
        }

        const self = this
        if (this.device.id) {
          api({
              endpoint: `${DEVICES_ENDPOINT}/${this.device.id}`,
              method: 'PUT',
              data: this.deviceData,
              addTokenToHeaders: true
          })
            .then(function (res) {
              if (res.data.errors && Object.keys(res.data.errors).length > 0) {
                Object.keys(res.data.errors).forEach(fieldName => {
                  this.hasErrors[fieldName] = res.data.errors[fieldName]
                })
              } else {
                document.location.reload()
              }
              this.spinners['update-' + uniqueIndex] = false
            }.bind(this),
            function(error) {
              self.spinners['update-' + uniqueIndex] = false
              console.log(error)
            })
        } else {

          api({
              endpoint: `${DEVICES_ENDPOINT}/`,
              method: 'POST',
              data: this.deviceData,
              addTokenToHeaders: true
          })
            .then(function (res) {
              if (res.data.errors && Object.keys(res.data.errors).length > 0) {
                Object.keys(res.data.errors).forEach(fieldName => {
                  this.hasErrors[fieldName] = res.data.errors[fieldName]
                })
              } else {
                this.$emit('remove-device-id-from-new-devices', uniqueIndex)
                this.toggled = true
              }
              this.spinners['update-' + uniqueIndex] = false
            }.bind(this),
            function(error) {
              self.spinners['update-' + uniqueIndex] = false
              console.log(error)
            })
        }
      },
      deleteDevice(uniqueIndex) {
        this.spinners['delete-' +uniqueIndex] = true
        this.deleteDeviceError = false
        this.deleteDeviceErrorMessage = ''

        this.hasErrors = {}

        const self = this
        api({
            endpoint: `${DEVICES_ENDPOINT}/${this.device.id}`,
            method: 'DELETE',
            addTokenToHeaders: true
        })
          .then(function (res) {
            if (!res.data || res.data.status !== 'success') {
              this.deleteDeviceError = true
              this.deleteDeviceErrorMessage = 'Error deleting device'
            } else {
              this.$emit('delete-device')
              this.spinners['delete-' + uniqueIndex] = false
            }
          }.bind(this),
          function(error) {
            self.spinners['delete-' + uniqueIndex] = false
            self.deleteDeviceError = true
            self.deleteDeviceErrorMessage = 'Error: ' + error
          })
      }
    }
  }
</script>